import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface StateSlice<RESPONSE_DATA> {
  isUpdatePending: boolean;
  isUpdateError: string | null;
  isUpdateSuccess: boolean;
  updateResponse: RESPONSE_DATA | null;
}

export function createAsyncDataUpdateSlice<
  REQUEST_DATA = undefined,
  RESPONSE_DATA = undefined,
>(name: string) {
  const initialState: StateSlice<RESPONSE_DATA> = {
    isUpdatePending: false,
    isUpdateError: null,
    isUpdateSuccess: false,
    updateResponse: null,
  };

  return createSlice({
    name,
    initialState,
    reducers: {
      updateData(_state, _action: PayloadAction<REQUEST_DATA>) {
        //empty handled by saga
      },

      setIsUpdatePending(state) {
        state.isUpdatePending = true;
        state.isUpdateError = null;
        state.isUpdateSuccess = false;
        state.updateResponse = null;
      },
      setIsUpdateError(state, action: PayloadAction<string | undefined>) {
        state.isUpdatePending = false;
        state.isUpdateError = action.payload || "Something went wrong";
        state.isUpdateSuccess = false;
        state.updateResponse = null;
      },
      setIsUpdateSuccess(state) {
        state.isUpdatePending = false;
        state.isUpdateSuccess = true;
        state.isUpdateError = null;
        state.updateResponse = null;
      },
      setIsUpdateSuccessWithData(state, action: PayloadAction<RESPONSE_DATA>) {
        state.isUpdatePending = false;
        state.isUpdateSuccess = true;
        state.isUpdateError = null;
        state.updateResponse = action.payload as Draft<RESPONSE_DATA>;
      },
      resetState(state) {
        state.isUpdatePending = false;
        state.isUpdateSuccess = false;
        state.isUpdateError = null;
        state.updateResponse = null;
      },
    },
  });
}
