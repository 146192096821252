import React, { useEffect, useRef, useState } from "react";
import { SlidingTabInterface } from "./SlidingTab";

interface SlidingTabsBarInterface {
  selectedTab?: number;
  onSelectTab: (tab: number) => void;
  children: React.ReactNode;
}

const SlidingTabsBar: React.FC<SlidingTabsBarInterface> = ({
  selectedTab = 0,
  onSelectTab,
  children,
}) => {
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[selectedTab];
      if (currentTab) {
        setTabUnderlineLeft(currentTab.offsetLeft);
        setTabUnderlineWidth(currentTab.clientWidth);
      }
    };

    setTabPosition();
  }, [selectedTab]);

  const handleTabClick = (index: number) => {
    onSelectTab(index);
  };

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(
      child as React.ReactElement<SlidingTabInterface>,
      {
        onTabClick: handleTabClick,
        index: index,
        tabsRef: tabsRef,
        selectedTab: selectedTab,
      },
    );
  });

  return (
    <div className="sliding-tabs-bar">
      <div
        className="sliding-tabs-bar__tab-underline"
        style={{
          width: tabUnderlineWidth,
          left: tabUnderlineLeft,
        }}
      />
      {childrenWithProps}
    </div>
  );
};

export default SlidingTabsBar;
