import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  IPRangeListResponse,
  IPRangePreview,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { axiosCsisApi } from "../../../../../../App";

const ipRangesInternalSlice = createAsyncArrayFetchSlice<
  IPRangePreview,
  string
>("ipRangesInternal");

export const ipRangesInternalReducer = ipRangesInternalSlice.reducer;

export const {
  fetchData: fetchIpRangesInternal,
  setIsPending: setIsIpRangesInternalFetchPending,
  setFetchError: setIpRangesInternalFetchError,
  setFetchSuccess: setFetchIpRangesInternalSuccess,
} = ipRangesInternalSlice.actions;

// Async stuff - sagas

function* fetchIpRangesInternalSaga(action: PayloadAction<string>) {
  yield put(setIsIpRangesInternalFetchPending());
  const query = {
    organization_id: action.payload,
  };

  try {
    const response: AxiosResponse<IPRangeListResponse> = yield call(
      axiosCsisApi.getTrustedIpsApi10InternalOrganizationTrustedIpsGet,
      query,
    );
    yield put(setFetchIpRangesInternalSuccess(response.data.payload));
  } catch (e) {
    yield put(setIpRangesInternalFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(fetchIpRangesInternal.toString(), fetchIpRangesInternalSaga);
}

export function* ipRangesInternalSagas() {
  yield all([actionWatcher()]);
}
