import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import {
  ConnectorExternalReadListResponse,
  ConnectorReadExternal,
  GetConnectorsApi10InternalConnectorGetParams,
} from "../../../../../api/openapi/data-contracts";
import { handleRequestError } from "../../../../../api/utils";

const connectorsSlice = createAsyncArrayFetchSlice<
  ConnectorReadExternal,
  GetConnectorsApi10InternalConnectorGetParams
>("connectors");

export const connectorsReducer = connectorsSlice.reducer;

export const {
  fetchData: fetchConnectors,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = connectorsSlice.actions;

// Async stuff - sagas

function* fetchConnectorsSaga(
  action: PayloadAction<GetConnectorsApi10InternalConnectorGetParams>,
) {
  yield put(setIsPending());

  try {
    const response: AxiosResponse<ConnectorExternalReadListResponse> =
      yield call(
        axiosCsisApi.getConnectorsApi10InternalConnectorGet,
        action.payload,
      );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchConnectors.toString(), fetchConnectorsSaga);
}

export function* connectorsSagas() {
  yield all([actionWatcher()]);
}
