import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  OrganizationInternalRead,
  PortalsFrontendApiInternalOrganizationModelsOrganizationReadDetailResponse,
  UserGrantedAccessDetailResponse,
  UserGrantedAccessPreview,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createAsyncObjectFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncObjectFetchSlice";
import { createAsyncDataUpdateSlice } from "../../../../../sliceHelpers/createAsyncDataUpdateSlice";

const customerInfoInternalSlice =
  createAsyncObjectFetchSlice<OrganizationInternalRead>("customerInfoInternal");

export const customerInfoInternalReducer = customerInfoInternalSlice.reducer;

export const {
  fetchData: fetchCustomerInfo,
  setIsPending: setIsCustomerInfoFetchPending,
  setFetchError: setCustomerInfoFetchError,
  setFetchSuccess: setFetchCustomerInfoSuccess,
} = customerInfoInternalSlice.actions;

const requestOrgAccessSlice = createAsyncDataUpdateSlice<
  string,
  UserGrantedAccessPreview
>("requestOrgAccess");

export const requestOrgAccessReducer = requestOrgAccessSlice.reducer;

export const {
  updateData: requestOrgAccess,
  setIsUpdatePending,
  setIsUpdateError,
  setIsUpdateSuccessWithData,
  resetState: resetOrgAccessState,
} = requestOrgAccessSlice.actions;

// Async stuff - sagas
function* fetchCustomerInfoSaga(action: PayloadAction<string>) {
  yield put(setIsCustomerInfoFetchPending());

  try {
    const response: AxiosResponse<PortalsFrontendApiInternalOrganizationModelsOrganizationReadDetailResponse> =
      yield call(
        axiosCsisApi.getOrganizationApi10InternalOrganizationExternalIdGet,
        action.payload,
      );
    yield put(setFetchCustomerInfoSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setCustomerInfoFetchError(errorMessage));
  }
}

function* requestOrgAccessSaga(action: PayloadAction<string>) {
  yield put(setIsUpdatePending());

  try {
    const response: AxiosResponse<UserGrantedAccessDetailResponse> = yield call(
      axiosCsisApi.requestAccessApi10InternalOrganizationExternalIdAccessPost,
      action.payload,
    );
    yield put(setIsUpdateSuccessWithData(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchCustomerInfo.toString(), fetchCustomerInfoSaga);
  yield takeLatest(requestOrgAccess.toString(), requestOrgAccessSaga);
}

export function* customerInfoInternalSagas() {
  yield all([actionWatcher()]);
}
