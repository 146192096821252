import classNames from "classnames";
import remarkGfm from "remark-gfm";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Button,
  Dialog,
  Divider,
  IconButton,
  InlineFormError,
  Skeleton,
  Stack,
} from "@csis.com/components";
import { isNullOrUndefined } from "@csis.com/components/src/utils/utils";
import { useTranslations } from "@csis.com/tip/src/translations/useTranslations";
import ErrorInfo from "../shared/ErrorInfo/ErrorInfo";
import { DateSpanWrapper as DateSpan } from "../wrappers/DateSpanWrapper";
import { getHasNewsBeenDisplayed, getNewsResult } from "./selector";
import {
  fetchNews,
  resetState,
  setHasNewsBeenDisplayed,
  updateNewsReadStatusAll,
} from "./slice";
import { NewsItem } from "./types";

const SingleNewsItem = ({ newsItem }: { newsItem: NewsItem }) => {
  const readClasses = classNames("news__circle-new", {
    "news__circle-new--hidden": !newsItem.unread,
  });

  return (
    <div className="news__single-news-item" key={newsItem.external_id}>
      <Stack align="center">
        <Stack isVertical>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              a: ({ href }) =>
                href ? (
                  <a className="link--with-styling" href={href}>
                    {href}
                  </a>
                ) : null,
            }}
          >
            {newsItem.content}
          </ReactMarkdown>
          <div className="f_semibold f_csis">
            <DateSpan date={newsItem.created} />
          </div>
        </Stack>
        <div>
          <div className={readClasses} />
        </div>
      </Stack>
    </div>
  );
};

const News: FC = () => {
  const { t } = useTranslations();
  const hasNewsBeenDisplayed = useSelector(getHasNewsBeenDisplayed);
  const { news, isNewsPending, newsFetchError, newsUpdateError } =
    useSelector(getNewsResult);

  const dispatch = useDispatch();

  const [isNewsDialogOpen, setIsNewsDialogOpen] = useState(false);

  useEffect(() => {
    if (isNullOrUndefined(news)) {
      dispatch(fetchNews({ limit: 10 }));
    }
  }, [dispatch, news]);

  const handleMarkAllAsRead = useCallback(() => {
    if (news) {
      dispatch(updateNewsReadStatusAll());
    }
  }, [news, dispatch]);

  const countUnreadNews = news?.filter((newsItem) => newsItem.unread).length;

  useEffect(() => {
    if (
      countUnreadNews &&
      countUnreadNews > 0 &&
      hasNewsBeenDisplayed !== undefined &&
      !hasNewsBeenDisplayed
    ) {
      setIsNewsDialogOpen(true);
      dispatch(setHasNewsBeenDisplayed(true));
    }
  }, [dispatch, countUnreadNews, hasNewsBeenDisplayed]);

  const handleToggleNewsDialog = () => {
    setIsNewsDialogOpen(() => !isNewsDialogOpen);

    if (!isNewsDialogOpen) {
      dispatch(resetState());
      dispatch(fetchNews({ limit: 10 }));
    }
  };

  return (
    <div className="news hide-on-mobile">
      <IconButton
        isCircle
        type="text"
        icon="bell"
        color="secondary"
        size="large"
        spacing="small"
        onButtonClick={handleToggleNewsDialog}
        dataTestId="news-icon-button"
        tooltipText={t("portal_news")}
      />
      {countUnreadNews !== undefined && countUnreadNews > 0 && (
        <div className="news__number">
          <Badge color="red" label={countUnreadNews} />
        </div>
      )}
      <div className="news__dialog-container">
        {isNewsDialogOpen && (
          <Dialog
            hasMask={false}
            name="news-dialog"
            size="normal"
            dataTestId={"news-dialog"}
            header={t("portal_news")}
            horizontalAlign={"right"}
            verticalAlign={"top"}
            onClose={handleToggleNewsDialog}
          >
            {!isNewsPending && !newsFetchError && (
              <Stack isVertical gutterSize="big">
                {newsUpdateError ? (
                  <Stack isExpanded align="center">
                    <Stack isExpanded>
                      <InlineFormError errorText={newsUpdateError} />
                    </Stack>
                    <Stack isExpanded justify="right">
                      <Button
                        name="portal-news-error-retry"
                        size="small"
                        type="text"
                        icon="refresh"
                        text={t("retry")}
                        onButtonClick={handleMarkAllAsRead}
                        dataTestId="portal-news-error-retry-btn"
                      />
                    </Stack>
                  </Stack>
                ) : countUnreadNews !== undefined && countUnreadNews > 0 ? (
                  <Stack isExpanded align="center">
                    <Button
                      name="portal-news-mark-all-as-read"
                      size="small"
                      type="primary"
                      isFullWidth
                      icon="check"
                      text={t("mark_all_as_read")}
                      onButtonClick={handleMarkAllAsRead}
                      dataTestId="portal-news-mark-all-as-read"
                    />
                  </Stack>
                ) : null}
                <div className="news__dialog-body">
                  {news && news.length > 0 ? (
                    <Stack isVertical>
                      {news.map((newsItem, i) => (
                        <Fragment key={newsItem.external_id}>
                          <SingleNewsItem newsItem={newsItem} />
                          {i !== news.length - 1 && <Divider />}
                        </Fragment>
                      ))}
                    </Stack>
                  ) : (
                    <p>{t("no_portal_news")}</p>
                  )}
                </div>
              </Stack>
            )}

            {isNewsPending && !newsFetchError && (
              <>
                <Skeleton type="multiline" />
                <Skeleton type="multiline" />
                <Skeleton type="multiline" />
              </>
            )}

            {!isNewsPending && newsFetchError && (
              <ErrorInfo
                error={newsFetchError}
                retryAction={() => dispatch(fetchNews({ limit: 10 }))}
              />
            )}
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default News;
