import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Form,
  FormLabel,
  GenericFormError,
  Input,
  Stack,
} from "@csis.com/components";
import BasicDialogButtons from "../../../../components/Form/BasicDialogButtons/BasicDialogButtons";
import BasicTextInput from "../../../../components/Form/BasicTextInput/BasicTextInput";
import BusinessUnitSelect from "../../BusinessUnitSelect/BusinessUnitSelect";
import { emergencyCaseKeys } from "../../EmergencyCaseSearch/constants";
import ResponsibleUserSelect from "../../ResponsibleUserSelect/ResponsibleUserSelect";
import { EmergencyCaseUpdateBody } from "../api/types";
import { getEmergencyCase, getEmergencyCaseUpdateResult } from "../selectors";
import { resetEmergencyCaseUpdateState, updateEmergencyCase } from "../slice";
import { BusinessUnit, BusinessUnitWithRetainer } from "../types";

const EditEmergencyCaseDialog = ({
  onDialogClose,
  onSuccess,
}: {
  onDialogClose: () => void;
  onSuccess: () => void;
}) => {
  const dispatch = useDispatch();
  const emergencyCase = useSelector(getEmergencyCase);

  const {
    emergencyCaseUpdateSuccess,
    emergencyCaseUpdateError,
    emergencyCaseUpdatePending,
  } = useSelector(getEmergencyCaseUpdateResult);

  const [emergencyCaseDraft, setEmergencyCaseDraft] =
    useState<EmergencyCaseUpdateBody>({
      [emergencyCaseKeys.RESPONSIBLE_EXTERNAL_ID]:
        emergencyCase?.[emergencyCaseKeys.RESPONSIBLE]?.id,
      [emergencyCaseKeys.BUSINESS_UNIT_ID]: undefined,
      [emergencyCaseKeys.NEW_CUSTOMER_NAME]:
        emergencyCase?.[emergencyCaseKeys.NEW_CUSTOMER_NAME],
      [emergencyCaseKeys.CONTACT_NAME]:
        emergencyCase?.[emergencyCaseKeys.CONTACT_NAME],
      [emergencyCaseKeys.CONTACT_PHONE_NUMBER]:
        emergencyCase?.[emergencyCaseKeys.CONTACT_PHONE_NUMBER],
      [emergencyCaseKeys.CONTACT_EMAIL]:
        emergencyCase?.[emergencyCaseKeys.CONTACT_EMAIL],
    });

  const handleFormSubmit = () => {
    const filteredEmergencyCaseDraft = Object.fromEntries(
      Object.entries(emergencyCaseDraft).filter(([_, value]) => value),
    );
    if (emergencyCase) {
      dispatch(
        updateEmergencyCase({
          caseId: emergencyCase?.external_id,
          case: filteredEmergencyCaseDraft,
        }),
      );
    }
  };

  const handleChangeEmergencyCaseField = (
    newValue: unknown,
    fieldKey: string,
  ) => {
    setEmergencyCaseDraft((prevState) => ({
      ...prevState,
      [fieldKey]: newValue,
    }));
  };

  const handleResponsibleUserChange = (value: string) => {
    setEmergencyCaseDraft((prevState) => ({
      ...prevState,
      [emergencyCaseKeys.RESPONSIBLE_EXTERNAL_ID]: value,
    }));
  };

  useEffect(() => {
    if (emergencyCaseUpdateSuccess && onSuccess) {
      onSuccess();
    }
  }, [onSuccess, emergencyCaseUpdateSuccess]);

  useEffect(() => {
    // runs when component unmounts
    return () => {
      dispatch(resetEmergencyCaseUpdateState());
    };
  }, [dispatch]);

  const onBusinessUnitChange = (
    businessUnit: BusinessUnit | BusinessUnitWithRetainer,
  ) => {
    setEmergencyCaseDraft((prevState) => ({
      ...prevState,
      [emergencyCaseKeys.BUSINESS_UNIT_ID]: businessUnit.id,
    }));
  };

  return (
    <Dialog
      name="emergency-case-information-dialog"
      onClose={onDialogClose}
      header="Edit Emergency Case Information"
    >
      <Form
        id="emergency-case-information-form"
        onFormSubmit={handleFormSubmit}
      >
        <Stack isVertical gutterSize="large" align="stretch">
          <Stack isVertical>
            <FormLabel
              text="Responsible"
              htmlFor={emergencyCaseKeys.RESPONSIBLE_EXTERNAL_ID}
            />
            <ResponsibleUserSelect
              responsibleUserId={emergencyCaseDraft.responsible_external_id}
              onChange={handleResponsibleUserChange}
            />
          </Stack>
          {!emergencyCase?.business_unit && (
            <>
              <BusinessUnitSelect
                businessUnitId={
                  emergencyCaseDraft[emergencyCaseKeys.BUSINESS_UNIT_ID]
                }
                onBusinessUnitChange={onBusinessUnitChange}
              />
              <Stack isVertical>
                <FormLabel
                  text="Name of non-existing customer"
                  htmlFor={emergencyCaseKeys.NEW_CUSTOMER_NAME}
                />
                <Input
                  name={emergencyCaseKeys.NEW_CUSTOMER_NAME}
                  type="text"
                  isFullWidth
                  placeholder={"New Customer Name"}
                  value={emergencyCaseDraft.new_customer_name}
                  onChange={handleChangeEmergencyCaseField}
                  dataTestId="new-case-new-customer-name-input"
                  isOptional={!!emergencyCaseDraft.business_unit_external_id}
                />
              </Stack>
            </>
          )}

          {[
            {
              label: "Contact name",
              name: emergencyCaseKeys.CONTACT_NAME,
              type: "text" as "text",
              value: emergencyCaseDraft.contact_name,
              placeholder: "Contact name",
              dataTestId: "new-case-contact-name-input",
            },
            {
              label: "Mobile phone number (we are sending SMS!)",
              name: emergencyCaseKeys.CONTACT_PHONE_NUMBER,
              type: "tel" as "tel",
              value: emergencyCaseDraft.contact_phone_number,
              placeholder: "+XX0123456789",
              dataTestId: "new-case-phone-number-input",
            },
            {
              label: "Email",
              name: emergencyCaseKeys.CONTACT_EMAIL,
              type: "text" as "text",
              value: emergencyCaseDraft.contact_email,
              placeholder: "Email",
              dataTestId: "new-case-email-input",
              isOptional: true,
            },
          ].map((field, index) => (
            <BasicTextInput
              key={index}
              label={field.label}
              name={field.name}
              type={field.type}
              value={field.value}
              onChange={handleChangeEmergencyCaseField}
              placeholder={field.placeholder}
              dataTestId={field.dataTestId}
              isOptional={field.isOptional}
            />
          ))}

          {emergencyCaseUpdateError && (
            <GenericFormError errorText={emergencyCaseUpdateError} />
          )}
          <BasicDialogButtons
            onCancel={onDialogClose}
            onSubmit={() => {}}
            formId={"emergency-case-information-form"}
            submitActionText={"Save Changes"}
            isSubmitActionPending={emergencyCaseUpdatePending}
          />
        </Stack>
      </Form>
    </Dialog>
  );
};
export default EditEmergencyCaseDialog;
