import { IconKind } from "../../icons/Icon";
import { areArraysEqualSets } from "../../utils/utils";
import { DropdownOption } from "./Dropdown";

export function isAllOptionsChecked<T>(
  options: DropdownOption<T>[],
  values?: T[],
) {
  if (values) {
    const optionsArray = options.map((option) => option.value);
    return areArraysEqualSets(optionsArray, values);
  } else {
    return false;
  }
}

export function getHeaderTitle<T extends any>(
  options: DropdownOption<T>[],
  headerTitle: string,
  value: T,
) {
  if (value !== undefined) {
    return options.find((option) => option.value === value)?.label;
  } else {
    return headerTitle;
  }
}

export function getHeaderIcon<T extends any>(
  options: DropdownOption<T>[],
  value?: T,
  headerIcon?: IconKind,
): IconKind | null {
  if (value !== undefined) {
    // first try to find if the selected value has an icon, then show that
    const valueIcon = options.find((option) => option.value === value)?.icon;
    return valueIcon ? valueIcon : null;
  } else {
    // or else show the "default" header icon, if exists
    return headerIcon ? headerIcon : null;
  }
}

export function getHeaderIconForMultiSelect(headerIcon?: IconKind) {
  // show the "default" header icon, if exists
  return headerIcon ? headerIcon : null;
}

export function getHeaderTitleForMultiSelect<T extends any>(
  options: DropdownOption<T>[],
  headerTitle: string,
  showNumberOfSelectedItems: boolean,
  values: T[] | undefined,
) {
  const totalValues = values?.length;
  const selectedValue = values?.[0];

  if (showNumberOfSelectedItems) {
    if (totalValues === 1 && selectedValue) {
      return typeof selectedValue === "string" ||
        typeof selectedValue === "number"
        ? options.find((option) => option.value === selectedValue)?.label
        : headerTitle;
    } else if (
      totalValues !== undefined &&
      (totalValues === 0 || totalValues > 1)
    ) {
      return `${totalValues} ${headerTitle} selected`;
    }
  }
  return headerTitle;
}
