import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  OrganizationCreateBody,
  OrganizationUpdateBody,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { createAsyncDataUpdateSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncDataUpdateSlice";

const organizationsUpdateSlice = createAsyncDataUpdateSlice<OrgModifyPayload>(
  "organizationsUpdate",
);

export const organizationsUpdateReducer = organizationsUpdateSlice.reducer;

export type OrgCreatePayload = {
  data: OrganizationCreateBody;
  action: "create";
};

export type OrgUpdatePayload = {
  id: string;
  data: OrganizationUpdateBody;
  action: "update";
};
export type OrgDeletePayload = { id: string; action: "delete" };

export type OrgModifyPayload =
  | OrgCreatePayload
  | OrgUpdatePayload
  | OrgDeletePayload;

export const {
  updateData: modifyOrganizations,
  setIsUpdatePending,
  setIsUpdateError,
  setIsUpdateSuccess,
  resetState: resetOrganizationsModifyState,
} = organizationsUpdateSlice.actions;

// Async stuff - sagas

function* modifyOrganizationsSaga(action: PayloadAction<OrgModifyPayload>) {
  yield put(setIsUpdatePending());

  try {
    if (action.payload.action === "create") {
      yield call(
        axiosCsisApi.postOrganizationApi10InternalOrganizationPost,
        action.payload.data,
      );
    } else if (action.payload.action === "delete") {
      yield call(
        axiosCsisApi.deleteOrganizationApi10InternalOrganizationExternalIdDelete,
        action.payload.id,
      );
    } else if (action.payload.action === "update") {
      yield call(
        axiosCsisApi.putOrganizationApi10InternalOrganizationExternalIdPut,
        action.payload.id,
        action.payload.data,
      );
    } else {
      throw new Error("Invalid action");
    }

    yield put(setIsUpdateSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(modifyOrganizations.toString(), modifyOrganizationsSaga);
}

export function* organizationsUpdateSagas() {
  yield all([actionWatcher()]);
}
