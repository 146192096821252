import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  ServiceSubscriptionCreate,
  ServiceSubscriptionRead,
  ServiceSubscriptionReadListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { handleRequestError } from "../../../../../api/utils";
import { createAsyncDataUpdateSlice } from "../../../../../sliceHelpers/createAsyncDataUpdateSlice";
import { getSelectedOrgId } from "../../../../Profile/Security/selectors";

const serviceSubscriptionsSlice = createAsyncArrayFetchSlice<
  ServiceSubscriptionRead,
  string
>("serviceSubscriptions");

export const serviceSubscriptionsReducer = serviceSubscriptionsSlice.reducer;

export const {
  fetchData: fetchServiceSubscriptions,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = serviceSubscriptionsSlice.actions;

export type PostPayload = {
  data: ServiceSubscriptionCreate;
  action: "create";
};

export type UpdatePayload = {
  id: string;
  data: {};
  action: "update";
};

export type DeletePayload = {
  id: string;
  action: "delete";
};

const serviceSubscriptionsUpdateSlice = createAsyncDataUpdateSlice<
  PostPayload | UpdatePayload | DeletePayload
>("serviceSubscriptionsUpdate");

export const serviceSubscriptionsUpdateReducer =
  serviceSubscriptionsUpdateSlice.reducer;

export const {
  updateData: modifyServiceSubscription,
  setIsUpdatePending,
  setIsUpdateError,
  setIsUpdateSuccess,
  resetState: resetServiceSubscriptionsUpdateState,
} = serviceSubscriptionsUpdateSlice.actions;

// Async stuff - sagas

function* fetchServiceSubscriptionsSaga(action: PayloadAction<string>) {
  const query = {
    organization_id: action.payload,
  };

  yield put(setIsPending());
  try {
    const response: AxiosResponse<ServiceSubscriptionReadListResponse> =
      yield call(
        axiosCsisApi.getBaseServiceSubscriptionsApi10InternalServiceSubscriptionBaseGet,
        query,
      );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* modifyServiceSubscriptionsSaga(
  action: PayloadAction<PostPayload | UpdatePayload | DeletePayload>,
) {
  yield put(setIsUpdatePending());
  const organizationId: string = yield select(getSelectedOrgId) || "";

  try {
    if (action.payload.action === "create") {
      yield call(
        axiosCsisApi.postServiceSubscriptionApi10InternalServiceSubscriptionPost,
        { organization_id: organizationId },
        action.payload.data,
      );
    } else if (action.payload.action === "update") {
      // TODO
    } else {
      yield call(
        axiosCsisApi.deleteServiceSubscriptionApi10InternalServiceSubscriptionExternalIdDelete,
        { organization_id: organizationId, externalId: action.payload.id },
      );
    }
    yield put(setIsUpdateSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setIsUpdateError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchServiceSubscriptions.toString(),
    fetchServiceSubscriptionsSaga,
  );
  yield takeLatest(
    modifyServiceSubscription.toString(),
    modifyServiceSubscriptionsSaga,
  );
}

export function* serviceSubscriptionsSagas() {
  yield all([actionWatcher()]);
}
