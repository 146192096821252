/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActionApi10InternalConnectorActionPostParams,
  ActivityListResponse,
  AdminUserPreviewListResponse,
  AlertDetailResponse,
  AlertPreviewPageDetailResponse,
  AlertUpdateBody,
  AlerterPreviewListResponse,
  ArticleCategoryDetailResponse,
  ArticleDetailResponse,
  ArticlePreviewPageDetailResponse,
  ArtifactType,
  AuditlogEventPageDetailResponse,
  BaseResponse,
  BodyAcknowledgeEmergencyResponseCaseApi10EmergencyResponseCaseErCaseExternalIdCallbackTigerTeamPost,
  BodyUploadAttachmentApi20TicketAttachmentPost,
  BodyUploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost,
  BodyUploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost,
  BodyUploadRetainerAttachmentApi20RetainerAttachmentPost,
  BodyUploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost,
  BulkReportUpdateBody,
  BulkUrlSubmitBody,
  BusinessUnitPreviewPageDetailResponse,
  BusinessUnitWithRetainerPreviewPageDetailResponse,
  ChangePasswordRequestBody,
  CheckResetTokenBody,
  ClientListResponse,
  ConnectorActionDetailResponse,
  ConnectorActionsBody,
  ConnectorCreateDarktraceAiAnalystModel,
  ConnectorCreateDarktraceModelBreachesModel,
  ConnectorCreateSentinelModel,
  ConnectorCreateXDRModel,
  ConnectorExternalReadListResponse,
  ConnectorReadDetailResponse,
  CreditCardPageResponse,
  DeleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDeleteParams,
  DeleteServiceSubscriptionApi10InternalServiceSubscriptionExternalIdDeleteParams,
  DetailResponse,
  DetailResponseBulkReportUpdateResponse,
  DetailResponseBulkUrlSubmitResponse,
  DetailResponseContentFullPage,
  DetailResponseCreditCard,
  DetailResponseCsrfToken,
  DetailResponseLegacyStatsResponse,
  DetailResponsePortalnewsPreviewPage,
  DetailResponseReportPreview,
  DetailResponseRetainerPreview,
  DetailResponseURLFull,
  DetailResponseURLPreviewPage,
  DetailResponseVictim,
  DownloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGetParams,
  ERCaseQuestionnairePreviewDetailResponse,
  ERCaseQuestionnaireUpdateBody,
  ERCaseSubmissionPreviewDetailResponse,
  EmailPasswordResetInput,
  EmergencyResponseCaseAttachmentsPreviewDetailResponse,
  EmergencyResponseCaseCreateBody,
  EmergencyResponseCasePreviewDetailResponse,
  EmergencyResponseCasePreviewPageDetailResponse,
  EmergencyResponseCaseUpdateBody,
  EntityListResponse,
  ErrorResponse,
  EscalationContactUpdateBody,
  ExportUrlsApi20PhishingUrlExportCsvGetParams,
  GetAlertsApi20AlertGetParams,
  GetArticlesApi20ArticleGetParams,
  GetAsnsApi20PhishingAsnGetParams,
  GetBaseServiceSubscriptionsApi10InternalServiceSubscriptionBaseGetParams,
  GetBusinessUnitsApi10AdminBusinessUnitGetParams,
  GetBusinessUnitsWithRetainersByAdminApi10AdminRetainerGetParams,
  GetChildServiceSubscriptionsApi10InternalServiceSubscriptionBaseExternalIdChildrenGetParams,
  GetConnectorApi10InternalConnectorExternalIdGetParams,
  GetConnectorsApi10InternalConnectorGetParams,
  GetContentsApi20PhishingContentUrlHashGetParams,
  GetCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGetParams,
  GetCreditCardHistogramApi20CompromisedDataCreditCardReportStatisticsMatcherHistogramGetParams,
  GetCreditCardReportsApi20CompromisedDataCreditCardReportGetParams,
  GetCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGetParams,
  GetCreditCardStatsStateApi20CompromisedDataCreditCardReportStatisticsStatusGetParams,
  GetEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGetParams,
  GetEmergencyResponseCasesApi10AdminEmergencyResponseCaseGetParams,
  GetEntityApi20InvestigationEntityGetParams,
  GetEntityTypesApi20InvestigationEntityTypeGetParams,
  GetEscalationContactsApi10InternalOrganizationEscalationContactGetParams,
  GetEventsApi10AuditlogGetParams,
  GetExportCsvApi20AlertExportCsvGetParams,
  GetExportCsvApi20InvestigationExportCsvGetParams,
  GetExportCsvApi20TicketExportCsvGetParams,
  GetOrganizationsApi10InternalOrganizationGetParams,
  GetPortalNewsApi10PortalNewsGetParams,
  GetRelatedArticlesApi20ArticleExternalIdRelatedGetParams,
  GetReportsApi20ReportGetParams,
  GetStatisticsAlerterApi20AlertStatisticsAlerterGetParams,
  GetStatisticsAlerterHistogramApi20AlertStatisticsAlerterHistogramGetParams,
  GetStatisticsSeverityApi20AlertStatisticsSeverityGetParams,
  GetStatisticsSeverityApi20TicketStatisticsSeverityGetParams,
  GetStatisticsSeverityHistogramApi20AlertStatisticsSeverityHistogramGetParams,
  GetStatisticsSeverityHistogramApi20TicketStatisticsSeverityHistogramGetParams,
  GetStatisticsStatusApi20AlertStatisticsStatusGetParams,
  GetStatisticsStatusApi20TicketStatisticsStatusGetParams,
  GetStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGetParams,
  GetStatisticsTypeApi20TicketStatisticsTypeGetParams,
  GetStatisticsTypeHistogramApi20TicketStatisticsTypeHistogramGetParams,
  GetTicketsApi20TicketGetParams,
  GetTrustedIpsApi10InternalOrganizationTrustedIpsGetParams,
  GetUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGetParams,
  GetUrlStatsStatusApi20PhishingUrlStatisticsStatusGetParams,
  GetUrlsApi20PhishingUrlGetParams,
  GetUsersApi10InternalOrganizationUserGetParams,
  GetVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGetParams,
  GetVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGetParams,
  GetVictimReportsApi20CompromisedDataCredentialReportGetParams,
  GetVictimSourceStatsApi20CompromisedDataCredentialReportStatisticsSourceGetParams,
  GetVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGetParams,
  HTTPValidationError,
  IPRangeCreateBody,
  IPRangeListResponse,
  IPRangePreviewResponse,
  IPRangeUpdateBody,
  ListResponseASNPreview,
  ListResponseBrandPreview,
  ListResponseCountryPreview,
  ListResponseCustomerFull,
  ListResponseEntityTypePayload,
  ListResponsePortalAuditLogEntry,
  ListResponseReportType,
  ListResponseRequestOption,
  ListResponseRetainerAttachmentPreview,
  MDFESetting,
  NotificationDigestPreferenceSet,
  NotificationDigestPreferenceSetListResponse,
  NotificationDigestPreferenceSimpleSetListResponse,
  OrganizationCreateBody,
  OrganizationDomainsListResponse,
  OrganizationDomainsUpdate,
  OrganizationReadPageDetailResponse,
  OrganizationTypeListResponse,
  OrganizationUpdateBody,
  PortalsFrontendApiInternalOrganizationModelsEscalationContactListResponse,
  PortalsFrontendApiInternalOrganizationModelsOrganizationReadDetailResponse,
  PortalsFrontendApiInternalOrganizationModelsUserListResponse,
  PortalsFrontendApiOrganizationModelsEscalationContactListResponse,
  PortalsFrontendApiOrganizationModelsOrganizationReadDetailResponse,
  PortalsFrontendApiOrganizationModelsUserListResponse,
  PostConnectorApi10InternalConnectorPostParams,
  PostServiceSubscriptionApi10InternalServiceSubscriptionPostParams,
  ProfileDetailResponse,
  ProfilePreviewDetailResponse,
  ReportPreviewPageDetailResponse,
  ReportRequest,
  ReportUpdateBody,
  ResendMessageType,
  RetainerCreateBody,
  RetainerCreateOrUpdateBody,
  RetainerTypePreviewListResponse,
  RetainerWithBusinessUnitPreviewDetailResponse,
  RetrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGetParams,
  RoleDetailListResponse,
  SectorListResponse,
  ServiceReadDetailResponse,
  ServiceReadListResponse,
  ServiceSubscriptionCreate,
  ServiceSubscriptionReadDetailResponse,
  ServiceSubscriptionReadListResponse,
  SetErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatchParams,
  SetPasswordRequestBody,
  SimpleNotificationDigestPreference,
  StatisticsCountListResponse,
  StatisticsHistogramCountListResponse,
  StorageCreate,
  StorageCreateResponse,
  StorageTypePreviewResponse,
  TicketCommentCreateBody,
  TicketCommentPreviewDetailResponse,
  TicketCommentPreviewListResponse,
  TicketCreateBody,
  TicketFeedbackCreateBody,
  TicketFeedbackPreviewDetailResponse,
  TicketFlagTypePreviewListResponse,
  TicketFullDetailResponse,
  TicketPreviewPageDetailResponse,
  TicketUpdateBody,
  TokenStatusResponse,
  UpdateClientBody,
  UpdateUserBody,
  UploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPostParams,
  UserContactInfoUpdate,
  UserGrantedAccessDetailResponse,
  UserGroupChangeUsers,
  UserGroupDetailListResponse,
  UserGroupDetailResponse,
  UserGroupUpdate,
  UserOTPDetailResponse,
  UserOTPVerification,
  VictimPageResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name IndexApi10Get
   * @summary Index
   * @request GET:/api/1.0/
   * @secure
   */
  indexApi10Get = (params: RequestParams = {}) =>
    this.request<BaseResponse, ErrorResponse>({
      path: `/api/1.0/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertersApi20AlertAlerterGet
   * @summary Get Alerters
   * @request GET:/api/2.0/alert/alerter
   * @secure
   */
  getAlertersApi20AlertAlerterGet = (params: RequestParams = {}) =>
    this.request<AlerterPreviewListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/alerter`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertApi20AlertExternalIdGet
   * @summary Get Alert
   * @request GET:/api/2.0/alert/{external_id}
   * @secure
   */
  getAlertApi20AlertExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<AlertDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name EditStatusApi20AlertExternalIdPatch
   * @summary Edit Status
   * @request PATCH:/api/2.0/alert/{external_id}
   * @secure
   */
  editStatusApi20AlertExternalIdPatch = (externalId: string, data: AlertUpdateBody, params: RequestParams = {}) =>
    this.request<AlertDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAlertsApi20AlertGet
   * @summary Get Alerts
   * @request GET:/api/2.0/alert/
   * @secure
   */
  getAlertsApi20AlertGet = (query: GetAlertsApi20AlertGetParams, params: RequestParams = {}) =>
    this.request<AlertPreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetExportCsvApi20AlertExportCsvGet
   * @summary Get Export Csv
   * @request GET:/api/2.0/alert/export/csv
   * @secure
   */
  getExportCsvApi20AlertExportCsvGet = (query: GetExportCsvApi20AlertExportCsvGetParams, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetAttachmentApi20AlertExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/2.0/alert/{external_id}/attachment/{attachment_external_id}
   * @secure
   */
  getAttachmentApi20AlertExternalIdAttachmentAttachmentExternalIdGet = (
    attachmentExternalId: string,
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/${externalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsStatusApi20AlertStatisticsStatusGet
   * @summary Get Statistics Status
   * @request GET:/api/2.0/alert/statistics/status
   * @secure
   */
  getStatisticsStatusApi20AlertStatisticsStatusGet = (
    query: GetStatisticsStatusApi20AlertStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGet
   * @summary Get Statistics Status Histogram
   * @request GET:/api/2.0/alert/statistics/status/histogram
   * @secure
   */
  getStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGet = (
    query: GetStatisticsStatusHistogramApi20AlertStatisticsStatusHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/status/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsSeverityApi20AlertStatisticsSeverityGet
   * @summary Get Statistics Severity
   * @request GET:/api/2.0/alert/statistics/severity
   * @secure
   */
  getStatisticsSeverityApi20AlertStatisticsSeverityGet = (
    query: GetStatisticsSeverityApi20AlertStatisticsSeverityGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/severity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsSeverityHistogramApi20AlertStatisticsSeverityHistogramGet
   * @summary Get Statistics Severity Histogram
   * @request GET:/api/2.0/alert/statistics/severity/histogram
   * @secure
   */
  getStatisticsSeverityHistogramApi20AlertStatisticsSeverityHistogramGet = (
    query: GetStatisticsSeverityHistogramApi20AlertStatisticsSeverityHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/severity/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsAlerterApi20AlertStatisticsAlerterGet
   * @summary Get Statistics Alerter
   * @request GET:/api/2.0/alert/statistics/alerter
   * @secure
   */
  getStatisticsAlerterApi20AlertStatisticsAlerterGet = (
    query: GetStatisticsAlerterApi20AlertStatisticsAlerterGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/alerter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags alerts
   * @name GetStatisticsAlerterHistogramApi20AlertStatisticsAlerterHistogramGet
   * @summary Get Statistics Alerter Histogram
   * @request GET:/api/2.0/alert/statistics/alerter/histogram
   * @secure
   */
  getStatisticsAlerterHistogramApi20AlertStatisticsAlerterHistogramGet = (
    query: GetStatisticsAlerterHistogramApi20AlertStatisticsAlerterHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/alert/statistics/alerter/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetArticlesApi20ArticleGet
   * @summary Get Articles
   * @request GET:/api/2.0/article/
   * @secure
   */
  getArticlesApi20ArticleGet = (query: GetArticlesApi20ArticleGetParams, params: RequestParams = {}) =>
    this.request<ArticlePreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetArticleApi20ArticleExternalIdGet
   * @summary Get Article
   * @request GET:/api/2.0/article/{external_id}/
   * @secure
   */
  getArticleApi20ArticleExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<ArticleDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/${externalId}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetAttachmentApi20ArticleExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/2.0/article/{external_id}/attachment/{attachment_external_id}
   * @secure
   */
  getAttachmentApi20ArticleExternalIdAttachmentAttachmentExternalIdGet = (
    attachmentExternalId: string,
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/${externalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetRelatedArticlesApi20ArticleExternalIdRelatedGet
   * @summary Get Related Articles
   * @request GET:/api/2.0/article/{external_id}/related
   * @secure
   */
  getRelatedArticlesApi20ArticleExternalIdRelatedGet = (
    { externalId, ...query }: GetRelatedArticlesApi20ArticleExternalIdRelatedGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ArticlePreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/${externalId}/related`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags articles
   * @name GetCategoriesApi20ArticleCategoryGet
   * @summary Get Categories
   * @request GET:/api/2.0/article/category
   * @secure
   */
  getCategoriesApi20ArticleCategoryGet = (params: RequestParams = {}) =>
    this.request<ArticleCategoryDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/article/category`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Gets a page of auditlog events.
   *
   * @tags auditlog
   * @name GetEventsApi10AuditlogGet
   * @summary Get Events
   * @request GET:/api/1.0/auditlog/
   * @secure
   */
  getEventsApi10AuditlogGet = (query: GetEventsApi10AuditlogGetParams, params: RequestParams = {}) =>
    this.request<AuditlogEventPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/auditlog/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Gets a list of auditlog event activities.
   *
   * @tags auditlog
   * @name GetEventActivitiesApi10AuditlogActivityGet
   * @summary Get Event Activities
   * @request GET:/api/1.0/auditlog/activity/
   * @secure
   */
  getEventActivitiesApi10AuditlogActivityGet = (params: RequestParams = {}) =>
    this.request<ActivityListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/auditlog/activity/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get all organization types.
   *
   * @tags internal, organization
   * @name GetOrganizationTypesApi10InternalOrganizationTypesGet
   * @summary Get Organization Types
   * @request GET:/api/1.0/internal/organization/types/
   * @secure
   */
  getOrganizationTypesApi10InternalOrganizationTypesGet = (params: RequestParams = {}) =>
    this.request<OrganizationTypeListResponse, ErrorResponse>({
      path: `/api/1.0/internal/organization/types/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get organization by its external id and clearance_level. :param external_id: The external id of the organization. :param clearance_level: The clearance level of the current user. :returns: An organization.
   *
   * @tags internal, organization
   * @name GetOrganizationApi10InternalOrganizationExternalIdGet
   * @summary Get Organization
   * @request GET:/api/1.0/internal/organization/{external_id}
   * @secure
   */
  getOrganizationApi10InternalOrganizationExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiInternalOrganizationModelsOrganizationReadDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/internal/organization/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update an organization. :param external_id: The organizations external id :param organization_body: The organization data. :param clearance_level: The clearance level of the current user. :return: The updated organization.
   *
   * @tags internal, organization
   * @name PutOrganizationApi10InternalOrganizationExternalIdPut
   * @summary Put Organization
   * @request PUT:/api/1.0/internal/organization/{external_id}
   * @secure
   */
  putOrganizationApi10InternalOrganizationExternalIdPut = (
    externalId: string,
    data: OrganizationUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiInternalOrganizationModelsOrganizationReadDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/internal/organization/${externalId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Soft-delete the organization with the given external id. :param external_id: The external id of the organization. :param clearance_level: The clearance level of the current user.
   *
   * @tags internal, organization
   * @name DeleteOrganizationApi10InternalOrganizationExternalIdDelete
   * @summary Delete Organization
   * @request DELETE:/api/1.0/internal/organization/{external_id}
   * @secure
   */
  deleteOrganizationApi10InternalOrganizationExternalIdDelete = (externalId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/organization/${externalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Get a page of organizations, potentially filtered by deletion status, sectors, and countries. :param include_deleted: If deleted orgs should be included :param query_text: The query to filter organization name by. :param sectors: The sectors to filter by. :param countries: The countries to filter by, using ISO 3166-1 alpha-2 format. :param services: The services to filter by. :param clearance_level: The clearance level of the current user. :param sorting: The sorting configuration. :param pagination: The pagination configuration. :return: A page of organizations.
   *
   * @tags internal, organization
   * @name GetOrganizationsApi10InternalOrganizationGet
   * @summary Get Organizations
   * @request GET:/api/1.0/internal/organization/
   * @secure
   */
  getOrganizationsApi10InternalOrganizationGet = (
    query: GetOrganizationsApi10InternalOrganizationGetParams,
    params: RequestParams = {},
  ) =>
    this.request<OrganizationReadPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/organization/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create a new organization. :param organization_body: The organization data. :param clearance_level: The clearance level of the current user. :return: The created organization.
   *
   * @tags internal, organization
   * @name PostOrganizationApi10InternalOrganizationPost
   * @summary Post Organization
   * @request POST:/api/1.0/internal/organization/
   * @secure
   */
  postOrganizationApi10InternalOrganizationPost = (data: OrganizationCreateBody, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiInternalOrganizationModelsOrganizationReadDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/internal/organization/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags internal, organization
   * @name GetSectorsApi10InternalOrganizationSectorGet
   * @summary Get Sectors
   * @request GET:/api/1.0/internal/organization/sector/
   * @secure
   */
  getSectorsApi10InternalOrganizationSectorGet = (params: RequestParams = {}) =>
    this.request<SectorListResponse, ErrorResponse>({
      path: `/api/1.0/internal/organization/sector/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get escalation contacts from an organization. :param organization: The organization id. :return: The escalation contacts or empty list
   *
   * @tags internal, organization
   * @name GetEscalationContactsApi10InternalOrganizationEscalationContactGet
   * @summary Get Escalation Contacts
   * @request GET:/api/1.0/internal/organization/escalation-contact/
   * @secure
   */
  getEscalationContactsApi10InternalOrganizationEscalationContactGet = (
    query: GetEscalationContactsApi10InternalOrganizationEscalationContactGetParams,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiInternalOrganizationModelsEscalationContactListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/internal/organization/escalation-contact/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get trusted ips from an organization. :param organization: The organization_external_id. :return: The trusted ips or empty list
   *
   * @tags internal, organization
   * @name GetTrustedIpsApi10InternalOrganizationTrustedIpsGet
   * @summary Get Trusted Ips
   * @request GET:/api/1.0/internal/organization/trusted_ips/
   * @secure
   */
  getTrustedIpsApi10InternalOrganizationTrustedIpsGet = (
    query: GetTrustedIpsApi10InternalOrganizationTrustedIpsGetParams,
    params: RequestParams = {},
  ) =>
    this.request<IPRangeListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/organization/trusted_ips/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update an organizations allowed domains. :param external_id: The organizations external id :param domains_body: The organization domains data. :param clearance_level: The clearance level of the current user. :return: The organization's updated domains.
   *
   * @tags internal, organization
   * @name PutOrganizationDomainsApi10InternalOrganizationExternalIdDomainsPut
   * @summary Put Organization Domains
   * @request PUT:/api/1.0/internal/organization/{external_id}/domains/
   * @secure
   */
  putOrganizationDomainsApi10InternalOrganizationExternalIdDomainsPut = (
    externalId: string,
    data: OrganizationDomainsUpdate,
    params: RequestParams = {},
  ) =>
    this.request<OrganizationDomainsListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/organization/${externalId}/domains/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get users across organizations :param include_deleted: includes deleted users :param query_text: text to search on, will search on the users' name and login name :return: The users and their organization
   *
   * @tags internal, organization
   * @name GetUsersApi10InternalOrganizationUserGet
   * @summary Get Users
   * @request GET:/api/1.0/internal/organization/user/
   * @secure
   */
  getUsersApi10InternalOrganizationUserGet = (
    query: GetUsersApi10InternalOrganizationUserGetParams,
    params: RequestParams = {},
  ) =>
    this.request<PortalsFrontendApiInternalOrganizationModelsUserListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/organization/user/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags internal, organization
   * @name RequestAccessApi10InternalOrganizationExternalIdAccessPost
   * @summary Request Access
   * @request POST:/api/1.0/internal/organization/{external_id}/access
   * @secure
   */
  requestAccessApi10InternalOrganizationExternalIdAccessPost = (externalId: string, params: RequestParams = {}) =>
    this.request<UserGrantedAccessDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/organization/${externalId}/access`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get a list of all services. :returns: The list of services.
   *
   * @tags internal, service
   * @name GetServicesApi10InternalServiceGet
   * @summary Get Services
   * @request GET:/api/1.0/internal/service/
   * @secure
   */
  getServicesApi10InternalServiceGet = (params: RequestParams = {}) =>
    this.request<ServiceReadListResponse, ErrorResponse>({
      path: `/api/1.0/internal/service/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get the service by its external id. :param external_id: The external id of the service. :returns: The service.
   *
   * @tags internal, service
   * @name GetServiceApi10InternalServiceExternalIdGet
   * @summary Get Service
   * @request GET:/api/1.0/internal/service/{external_id}
   * @secure
   */
  getServiceApi10InternalServiceExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<ServiceReadDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/service/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get all base (top-level) service subscriptions for a given organization that is accessible by the user. :returns: All  base service subscriptions for the given organization that is accessible by the user.
   *
   * @tags internal, service
   * @name GetBaseServiceSubscriptionsApi10InternalServiceSubscriptionBaseGet
   * @summary Get Base Service Subscriptions
   * @request GET:/api/1.0/internal/service/subscription/base/
   * @secure
   */
  getBaseServiceSubscriptionsApi10InternalServiceSubscriptionBaseGet = (
    query: GetBaseServiceSubscriptionsApi10InternalServiceSubscriptionBaseGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ServiceSubscriptionReadListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/service/subscription/base/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get all child service subscriptions for a given organization and parent service that is accessible by the user. :param external_id: The id of the parent service. :param organization: The organization that subscribes to the services. :returns: All child service subscriptions for the given organization and parent service subscription that is accessible by the user.
   *
   * @tags internal, service
   * @name GetChildServiceSubscriptionsApi10InternalServiceSubscriptionBaseExternalIdChildrenGet
   * @summary Get Child Service Subscriptions
   * @request GET:/api/1.0/internal/service/subscription/base/{external_id}/children/
   * @secure
   */
  getChildServiceSubscriptionsApi10InternalServiceSubscriptionBaseExternalIdChildrenGet = (
    {
      externalId,
      ...query
    }: GetChildServiceSubscriptionsApi10InternalServiceSubscriptionBaseExternalIdChildrenGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ServiceSubscriptionReadListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/service/subscription/base/${externalId}/children/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create a service subscription for an organization. :param body: The service subscription to create. :returns: The created service subscription.
   *
   * @tags internal, service
   * @name PostServiceSubscriptionApi10InternalServiceSubscriptionPost
   * @summary Post Service Subscription
   * @request POST:/api/1.0/internal/service/subscription/
   * @secure
   */
  postServiceSubscriptionApi10InternalServiceSubscriptionPost = (
    query: PostServiceSubscriptionApi10InternalServiceSubscriptionPostParams,
    data: ServiceSubscriptionCreate,
    params: RequestParams = {},
  ) =>
    this.request<ServiceSubscriptionReadDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/service/subscription/`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Delete a service subscription for an organization. :param external_id: The external id of the service subscription to delete. :param organization: The organization that subscribes to the given service.
   *
   * @tags internal, service
   * @name DeleteServiceSubscriptionApi10InternalServiceSubscriptionExternalIdDelete
   * @summary Delete Service Subscription
   * @request DELETE:/api/1.0/internal/service/subscription/{external_id}
   * @secure
   */
  deleteServiceSubscriptionApi10InternalServiceSubscriptionExternalIdDelete = (
    { externalId, ...query }: DeleteServiceSubscriptionApi10InternalServiceSubscriptionExternalIdDeleteParams,
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/service/subscription/${externalId}`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description Get External Connector and its external configuration
   *
   * @tags internal, connector
   * @name GetConnectorApi10InternalConnectorExternalIdGet
   * @summary Get Connector
   * @request GET:/api/1.0/internal/connector/{external_id}
   * @secure
   */
  getConnectorApi10InternalConnectorExternalIdGet = (
    { externalId, ...query }: GetConnectorApi10InternalConnectorExternalIdGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ConnectorReadDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/connector/${externalId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get all Connectors Data includes external configuration if the connector is associated with a service subscription that data is included
   *
   * @tags internal, connector
   * @name GetConnectorsApi10InternalConnectorGet
   * @summary Get Connectors
   * @request GET:/api/1.0/internal/connector/
   * @secure
   */
  getConnectorsApi10InternalConnectorGet = (
    query: GetConnectorsApi10InternalConnectorGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ConnectorExternalReadListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/connector/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create a new Connector and Create a new External Configuration in the External System
   *
   * @tags internal, connector
   * @name PostConnectorApi10InternalConnectorPost
   * @summary Post Connector
   * @request POST:/api/1.0/internal/connector/
   * @secure
   */
  postConnectorApi10InternalConnectorPost = (
    query: PostConnectorApi10InternalConnectorPostParams,
    data:
      | ConnectorCreateXDRModel
      | ConnectorCreateSentinelModel
      | ConnectorCreateDarktraceModelBreachesModel
      | ConnectorCreateDarktraceAiAnalystModel,
    params: RequestParams = {},
  ) =>
    this.request<ConnectorReadDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/connector/`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Perform actions on connectors Takes a list of actions and connector ids. Actions are executed as bulk where possible. The actions are grouped by action name and then performed on external connectors. Output is a list of actions with the results of the actions. Find the result of each action by matching the action name and connector id. Example Input: [ { connector_id: "1234-5678-9012-3456", action: { name: "Test Authentication", connector_type_name: "csis_connect_connector_configuration", }, }, { connector_id: "6543-2109-8765-4321", action: { name: "Test configuration (dry-run)", connector_type_name: "csis_connect_connector_configuration", }, }, ] Example Output: [ { success: True, action: { name: "Test Authentication", connector_type_name: "csis_connect_connector_configuration", }, connector_id: "1234-5678-9012-3456", response: { success: True, error: None, full_error: None, }, }, { success: True, action: { name: "Test configuration (dry-run)", connector_type_name: "csis_connect_connector_configuration", }, connector_id: "6543-2109-8765-4321", response: { alerts: [{}, {}], filters: {}, errors: None, full_error: None, }, }, ]
   *
   * @tags internal, connector
   * @name ActionApi10InternalConnectorActionPost
   * @summary Action
   * @request POST:/api/1.0/internal/connector/action
   * @secure
   */
  actionApi10InternalConnectorActionPost = (
    query: ActionApi10InternalConnectorActionPostParams,
    data: ConnectorActionsBody,
    params: RequestParams = {},
  ) =>
    this.request<ConnectorActionDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/internal/connector/action`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags investigations
   * @name GetEntityTypesApi20InvestigationEntityTypeGet
   * @summary Get Entity Types
   * @request GET:/api/2.0/investigation/entity-type
   * @secure
   */
  getEntityTypesApi20InvestigationEntityTypeGet = (
    query: GetEntityTypesApi20InvestigationEntityTypeGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ListResponseEntityTypePayload, ErrorResponse>({
      path: `/api/2.0/investigation/entity-type`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags investigations
   * @name GetEntityApi20InvestigationEntityGet
   * @summary Get Entity
   * @request GET:/api/2.0/investigation/entity
   * @secure
   */
  getEntityApi20InvestigationEntityGet = (
    query: GetEntityApi20InvestigationEntityGetParams,
    params: RequestParams = {},
  ) =>
    this.request<EntityListResponse, ErrorResponse>({
      path: `/api/2.0/investigation/entity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Generates a csv of an IFD lookup. Does the same modelling as `get_entity` and then afterwards does some non-pretty logic to flatten the result to be presentable in a csv.
   *
   * @tags investigations
   * @name GetExportCsvApi20InvestigationExportCsvGet
   * @summary Get Export Csv
   * @request GET:/api/2.0/investigation/export/csv
   * @secure
   */
  getExportCsvApi20InvestigationExportCsvGet = (
    query: GetExportCsvApi20InvestigationExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse>({
      path: `/api/2.0/investigation/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags onboarding
   * @name GetMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdGet
   * @summary Get Mdfe Customer Settings
   * @request GET:/api/1.0/onboarding/mdfe-customer/{external_id}
   * @secure
   */
  getMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<DetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/onboarding/mdfe-customer/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags onboarding
   * @name CreateMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdPost
   * @summary Create Mdfe Customer Settings
   * @request POST:/api/1.0/onboarding/mdfe-customer/{external_id}
   * @secure
   */
  createMdfeCustomerSettingsApi10OnboardingMdfeCustomerExternalIdPost = (
    externalId: string,
    data: MDFESetting,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/onboarding/mdfe-customer/${externalId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags onboarding
   * @name GetAzureLighthouseTemplateUrlApi10OnboardingSentinelCustomerAzureLighthouseGet
   * @summary Get Azure Lighthouse Template Url
   * @request GET:/api/1.0/onboarding/sentinel-customer/azure-lighthouse
   * @secure
   */
  getAzureLighthouseTemplateUrlApi10OnboardingSentinelCustomerAzureLighthouseGet = (params: RequestParams = {}) =>
    this.request<DetailResponse, ErrorResponse>({
      path: `/api/1.0/onboarding/sentinel-customer/azure-lighthouse`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name GetUsersApi10OrganizationUserGet
   * @summary Get Users
   * @request GET:/api/1.0/organization/user/
   * @secure
   */
  getUsersApi10OrganizationUserGet = (params: RequestParams = {}) =>
    this.request<PortalsFrontendApiOrganizationModelsUserListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name CreateUserApi10OrganizationUserPost
   * @summary Create User
   * @request POST:/api/1.0/organization/user/
   * @secure
   */
  createUserApi10OrganizationUserPost = (data: UpdateUserBody, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get all user groups of organization.
   *
   * @tags organization
   * @name GetUserGroupsApi10OrganizationUserGroupGet
   * @summary Get User Groups
   * @request GET:/api/1.0/organization/user-group/
   * @secure
   */
  getUserGroupsApi10OrganizationUserGroupGet = (params: RequestParams = {}) =>
    this.request<UserGroupDetailListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create user group. The starts_at and expires_at for the roles of the user group depend on the roles of the standard_user_group of the organization.
   *
   * @tags organization
   * @name CreateUserGroupApi10OrganizationUserGroupPost
   * @summary Create User Group
   * @request POST:/api/1.0/organization/user-group/
   * @secure
   */
  createUserGroupApi10OrganizationUserGroupPost = (data: UserGroupUpdate, params: RequestParams = {}) =>
    this.request<UserGroupDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name UpdateUserApi10OrganizationUserExternalIdPut
   * @summary Update User
   * @request PUT:/api/1.0/organization/user/{external_id}
   * @secure
   */
  updateUserApi10OrganizationUserExternalIdPut = (
    externalId: string,
    data: UpdateUserBody,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/${externalId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name DeleteUserApi10OrganizationUserExternalIdDelete
   * @summary Delete User
   * @request DELETE:/api/1.0/organization/user/{external_id}
   * @secure
   */
  deleteUserApi10OrganizationUserExternalIdDelete = (externalId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/${externalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name UndeleteUserApi10OrganizationUserExternalIdUndeletePatch
   * @summary Undelete User
   * @request PATCH:/api/1.0/organization/user/{external_id}/undelete
   * @secure
   */
  undeleteUserApi10OrganizationUserExternalIdUndeletePatch = (externalId: string, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user/${externalId}/undelete`,
      method: "PATCH",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update user group. The starts_at and expires_at for the roles of the user group depend on the roles of the standard_user_group of the organization.
   *
   * @tags organization
   * @name UpdateUserGroupApi10OrganizationUserGroupExternalIdPut
   * @summary Update User Group
   * @request PUT:/api/1.0/organization/user-group/{external_id}
   * @secure
   */
  updateUserGroupApi10OrganizationUserGroupExternalIdPut = (
    externalId: string,
    data: UserGroupUpdate,
    params: RequestParams = {},
  ) =>
    this.request<UserGroupDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/${externalId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Delete user group.
   *
   * @tags organization
   * @name DeleteUserGroupApi10OrganizationUserGroupExternalIdDelete
   * @summary Delete User Group
   * @request DELETE:/api/1.0/organization/user-group/{external_id}
   * @secure
   */
  deleteUserGroupApi10OrganizationUserGroupExternalIdDelete = (externalId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/${externalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Add users to user group.
   *
   * @tags organization
   * @name AddUsersToUserGroupsApi10OrganizationUserGroupExternalIdUserPut
   * @summary Add Users To User Groups
   * @request PUT:/api/1.0/organization/user-group/{external_id}/user
   * @secure
   */
  addUsersToUserGroupsApi10OrganizationUserGroupExternalIdUserPut = (
    externalId: string,
    data: UserGroupChangeUsers,
    params: RequestParams = {},
  ) =>
    this.request<UserGroupDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/${externalId}/user`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Remove users in user group.
   *
   * @tags organization
   * @name RemoveUsersInUserGroupApi10OrganizationUserGroupExternalIdUserDelete
   * @summary Remove Users In User Group
   * @request DELETE:/api/1.0/organization/user-group/{external_id}/user
   * @secure
   */
  removeUsersInUserGroupApi10OrganizationUserGroupExternalIdUserDelete = (
    externalId: string,
    data: UserGroupChangeUsers,
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-group/${externalId}/user`,
      method: "DELETE",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Get user roles of the organization.
   *
   * @tags organization
   * @name GetUserRolesApi10OrganizationUserRolesGet
   * @summary Get User Roles
   * @request GET:/api/1.0/organization/user-roles/
   * @secure
   */
  getUserRolesApi10OrganizationUserRolesGet = (params: RequestParams = {}) =>
    this.request<RoleDetailListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/user-roles/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get client roles of the organization.
   *
   * @tags organization
   * @name GetClientRolesApi10OrganizationClientRolesGet
   * @summary Get Client Roles
   * @request GET:/api/1.0/organization/client-roles/
   * @secure
   */
  getClientRolesApi10OrganizationClientRolesGet = (params: RequestParams = {}) =>
    this.request<RoleDetailListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client-roles/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get escalation contacts from organization. If organization_id is not provided, BU will be from the current user primary BU.
   *
   * @tags organization
   * @name GetEscalationContactsApi10OrganizationEscalationContactGet
   * @summary Get Escalation Contacts
   * @request GET:/api/1.0/organization/escalation-contact/
   * @secure
   */
  getEscalationContactsApi10OrganizationEscalationContactGet = (params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiOrganizationModelsEscalationContactListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/organization/escalation-contact/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Add escalation contacts to organization. If organization_id is not provided, BU will be from the current user primary BU.
   *
   * @tags organization
   * @name UpdateEscalationContactsApi10OrganizationEscalationContactPost
   * @summary Update Escalation Contacts
   * @request POST:/api/1.0/organization/escalation-contact/
   * @secure
   */
  updateEscalationContactsApi10OrganizationEscalationContactPost = (
    data: EscalationContactUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<
      PortalsFrontendApiOrganizationModelsEscalationContactListResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/organization/escalation-contact/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get approved ip ranges for a given organization.
   *
   * @tags organization
   * @name GetIpRangesApi10OrganizationIpRangeGet
   * @summary Get Ip Ranges
   * @request GET:/api/1.0/organization/ip-range/
   * @secure
   */
  getIpRangesApi10OrganizationIpRangeGet = (params: RequestParams = {}) =>
    this.request<IPRangeListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/ip-range/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Add ip-range to organization.
   *
   * @tags organization
   * @name AddIpRangeApi10OrganizationIpRangePost
   * @summary Add Ip Range
   * @request POST:/api/1.0/organization/ip-range/
   * @secure
   */
  addIpRangeApi10OrganizationIpRangePost = (data: IPRangeCreateBody, params: RequestParams = {}) =>
    this.request<IPRangePreviewResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/ip-range/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get organization by its external id.
   *
   * @tags organization
   * @name GetOrganizationApi10OrganizationExternalIdGet
   * @summary Get Organization
   * @request GET:/api/1.0/organization/{external_id}
   * @secure
   */
  getOrganizationApi10OrganizationExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<
      PortalsFrontendApiOrganizationModelsOrganizationReadDetailResponse,
      ErrorResponse | HTTPValidationError
    >({
      path: `/api/1.0/organization/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Updates ip-range identified by given external id.
   *
   * @tags organization
   * @name UpdateIpRangeApi10OrganizationIpRangeExternalIdPut
   * @summary Update Ip Range
   * @request PUT:/api/1.0/organization/ip-range/{external_id}
   * @secure
   */
  updateIpRangeApi10OrganizationIpRangeExternalIdPut = (
    externalId: string,
    data: IPRangeUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<IPRangePreviewResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/ip-range/${externalId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Delete ip-range from organization.
   *
   * @tags organization
   * @name DeleteRangeApi10OrganizationIpRangeExternalIdDelete
   * @summary Delete Range
   * @request DELETE:/api/1.0/organization/ip-range/{external_id}
   * @secure
   */
  deleteRangeApi10OrganizationIpRangeExternalIdDelete = (externalId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/ip-range/${externalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name GetClientsApi10OrganizationClientGet
   * @summary Get Clients
   * @request GET:/api/1.0/organization/client/
   * @secure
   */
  getClientsApi10OrganizationClientGet = (params: RequestParams = {}) =>
    this.request<ClientListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name CreateClientApi10OrganizationClientPost
   * @summary Create Client
   * @request POST:/api/1.0/organization/client/
   * @secure
   */
  createClientApi10OrganizationClientPost = (data: UpdateClientBody, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name UpdateClientApi10OrganizationClientExternalIdPut
   * @summary Update Client
   * @request PUT:/api/1.0/organization/client/{external_id}
   * @secure
   */
  updateClientApi10OrganizationClientExternalIdPut = (
    externalId: string,
    data: UpdateClientBody,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client/${externalId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags organization
   * @name DeleteClientApi10OrganizationClientExternalIdDelete
   * @summary Delete Client
   * @request DELETE:/api/1.0/organization/client/{external_id}
   * @secure
   */
  deleteClientApi10OrganizationClientExternalIdDelete = (externalId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/organization/client/${externalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetBrandsApi20PhishingBrandGet
   * @summary Get Brands
   * @request GET:/api/2.0/phishing/brand/
   * @secure
   */
  getBrandsApi20PhishingBrandGet = (params: RequestParams = {}) =>
    this.request<ListResponseBrandPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/brand/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetAsnsApi20PhishingAsnGet
   * @summary Get Asns
   * @request GET:/api/2.0/phishing/asn/
   * @secure
   */
  getAsnsApi20PhishingAsnGet = (query: GetAsnsApi20PhishingAsnGetParams, params: RequestParams = {}) =>
    this.request<ListResponseASNPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/asn/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetCountriesApi20PhishingCountryGet
   * @summary Get Countries
   * @request GET:/api/2.0/phishing/country/
   * @secure
   */
  getCountriesApi20PhishingCountryGet = (params: RequestParams = {}) =>
    this.request<ListResponseCountryPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/country/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetCustomerApi20PhishingCustomerGet
   * @summary Get Customer
   * @request GET:/api/2.0/phishing/customer/
   * @secure
   */
  getCustomerApi20PhishingCustomerGet = (params: RequestParams = {}) =>
    this.request<ListResponseCustomerFull, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/customer/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlsApi20PhishingUrlGet
   * @summary Get Urls
   * @request GET:/api/2.0/phishing/url/
   * @secure
   */
  getUrlsApi20PhishingUrlGet = (query: GetUrlsApi20PhishingUrlGetParams, params: RequestParams = {}) =>
    this.request<DetailResponseURLPreviewPage, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name PostUrlsApi20PhishingUrlPost
   * @summary Post Urls
   * @request POST:/api/2.0/phishing/url/
   * @secure
   */
  postUrlsApi20PhishingUrlPost = (data: BulkUrlSubmitBody, params: RequestParams = {}) =>
    this.request<DetailResponseBulkUrlSubmitResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlApi20PhishingUrlUrlHashGet
   * @summary Get Url
   * @request GET:/api/2.0/phishing/url/{url_hash}
   * @secure
   */
  getUrlApi20PhishingUrlUrlHashGet = (urlHash: string, params: RequestParams = {}) =>
    this.request<DetailResponseURLFull, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/${urlHash}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name ExportUrlsApi20PhishingUrlExportCsvGet
   * @summary Export Urls
   * @request GET:/api/2.0/phishing/url/export/csv/
   * @secure
   */
  exportUrlsApi20PhishingUrlExportCsvGet = (
    query: ExportUrlsApi20PhishingUrlExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/export/csv/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlStatsStatusApi20PhishingUrlStatisticsStatusGet
   * @summary Get Url Stats Status
   * @request GET:/api/2.0/phishing/url/statistics/status/
   * @secure
   */
  getUrlStatsStatusApi20PhishingUrlStatisticsStatusGet = (
    query: GetUrlStatsStatusApi20PhishingUrlStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/statistics/status/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetContentsApi20PhishingContentUrlHashGet
   * @summary Get Contents
   * @request GET:/api/2.0/phishing/content/{url_hash}
   * @secure
   */
  getContentsApi20PhishingContentUrlHashGet = (
    { urlHash, ...query }: GetContentsApi20PhishingContentUrlHashGetParams,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseContentFullPage, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/content/${urlHash}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetContentArtifactApi20PhishingContentUrlHashArtifactTypeContentIdGet
   * @summary Get Content Artifact
   * @request GET:/api/2.0/phishing/content/{url_hash}/{artifact_type}/{content_id}/
   * @secure
   */
  getContentArtifactApi20PhishingContentUrlHashArtifactTypeContentIdGet = (
    urlHash: string,
    artifactType: ArtifactType,
    contentId: number,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/content/${urlHash}/${artifactType}/${contentId}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags phishing
   * @name GetUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGet
   * @summary Get Url Stats Legacy
   * @request GET:/api/2.0/phishing/url/statistics/legacy/
   * @secure
   */
  getUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGet = (
    query: GetUrlStatsLegacyApi20PhishingUrlStatisticsLegacyGetParams,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseLegacyStatsResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/phishing/url/statistics/legacy/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description List portal news
   *
   * @tags portal-news
   * @name GetPortalNewsApi10PortalNewsGet
   * @summary Get Portal News
   * @request GET:/api/1.0/portal-news/
   * @secure
   */
  getPortalNewsApi10PortalNewsGet = (query: GetPortalNewsApi10PortalNewsGetParams, params: RequestParams = {}) =>
    this.request<DetailResponsePortalnewsPreviewPage, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/portal-news/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Set the last_read_portal_news timestamp to now
   *
   * @tags portal-news
   * @name SetLastReadApi10PortalNewsLastReadPost
   * @summary Set Last Read
   * @request POST:/api/1.0/portal-news/last-read
   * @secure
   */
  setLastReadApi10PortalNewsLastReadPost = (params: RequestParams = {}) =>
    this.request<BaseResponse, ErrorResponse>({
      path: `/api/1.0/portal-news/last-read`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetProfileApi10ProfileGet
   * @summary Get Profile
   * @request GET:/api/1.0/profile/
   * @secure
   */
  getProfileApi10ProfileGet = (params: RequestParams = {}) =>
    this.request<ProfileDetailResponse, ErrorResponse>({
      path: `/api/1.0/profile/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PostProfileApi10ProfilePatch
   * @summary Post Profile
   * @request PATCH:/api/1.0/profile/
   * @secure
   */
  postProfileApi10ProfilePatch = (data: UserContactInfoUpdate, params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetOtpApi10ProfileOtpGet
   * @summary Get Otp
   * @request GET:/api/1.0/profile/otp
   * @secure
   */
  getOtpApi10ProfileOtpGet = (params: RequestParams = {}) =>
    this.request<UserOTPDetailResponse, ErrorResponse>({
      path: `/api/1.0/profile/otp`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PostOtpApi10ProfileOtpPost
   * @summary Post Otp
   * @request POST:/api/1.0/profile/otp
   * @secure
   */
  postOtpApi10ProfileOtpPost = (data: UserOTPVerification, params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/otp`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name DeleteOtpApi10ProfileOtpDelete
   * @summary Delete Otp
   * @request DELETE:/api/1.0/profile/otp
   * @secure
   */
  deleteOtpApi10ProfileOtpDelete = (params: RequestParams = {}) =>
    this.request<void, ErrorResponse>({
      path: `/api/1.0/profile/otp`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PostTokenApi10ProfileTokenPost
   * @summary Post Token
   * @request POST:/api/1.0/profile/token
   * @secure
   */
  postTokenApi10ProfileTokenPost = (params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse>({
      path: `/api/1.0/profile/token`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name DeleteTokenApi10ProfileTokenDelete
   * @summary Delete Token
   * @request DELETE:/api/1.0/profile/token
   * @secure
   */
  deleteTokenApi10ProfileTokenDelete = (params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse>({
      path: `/api/1.0/profile/token`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PostPasswordApi10ProfilePasswordPost
   * @summary Post Password
   * @request POST:/api/1.0/profile/password
   * @secure
   */
  postPasswordApi10ProfilePasswordPost = (data: ChangePasswordRequestBody, params: RequestParams = {}) =>
    this.request<ProfilePreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/password`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetUserDataStoragePrefrenceApi10ProfileUserDataStorageTypeNameGet
   * @summary Get User Data Storage Prefrence
   * @request GET:/api/1.0/profile/user-data-storage/{type_name}
   * @secure
   */
  getUserDataStoragePrefrenceApi10ProfileUserDataStorageTypeNameGet = (typeName: string, params: RequestParams = {}) =>
    this.request<StorageCreateResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/user-data-storage/${typeName}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PutUserDataStorageApi10ProfileUserDataStorageTypeNamePut
   * @summary Put User Data Storage
   * @request PUT:/api/1.0/profile/user-data-storage/{type_name}
   * @secure
   */
  putUserDataStorageApi10ProfileUserDataStorageTypeNamePut = (
    typeName: string,
    data: StorageCreate,
    params: RequestParams = {},
  ) =>
    this.request<StorageCreateResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/user-data-storage/${typeName}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetUserDataStorageTypeNamesApi10ProfileUserDataStorageTypesGet
   * @summary Get User Data Storage Type Names
   * @request GET:/api/1.0/profile/user-data-storage/types
   * @secure
   */
  getUserDataStorageTypeNamesApi10ProfileUserDataStorageTypesGet = (params: RequestParams = {}) =>
    this.request<StorageTypePreviewResponse, ErrorResponse>({
      path: `/api/1.0/profile/user-data-storage/types`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetAuditLogApi10ProfileAuditLogGet
   * @summary Get Audit Log
   * @request GET:/api/1.0/profile/audit-log/
   * @secure
   */
  getAuditLogApi10ProfileAuditLogGet = (params: RequestParams = {}) =>
    this.request<ListResponsePortalAuditLogEntry, ErrorResponse>({
      path: `/api/1.0/profile/audit-log/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertGet
   * @summary Get Alert Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/alert
   * @secure
   */
  getAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertGet = (params: RequestParams = {}) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/alert`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Iterates through the List[NotificationDigestPreferenceSet] specified on the PATCH request body. For each NotificationDigestPreference, updates the specified user preference to the desired severity. If the severity specified in NotificationDigestPreference.digests is None, the specified user preference is deleted.
   *
   * @tags profile
   * @name PatchAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertPatch
   * @summary Patch Alert Notification Preferences
   * @request PATCH:/api/1.0/profile/preference/notification/alert
   * @secure
   */
  patchAlertNotificationPreferencesApi10ProfilePreferenceNotificationAlertPatch = (
    data: NotificationDigestPreferenceSet[],
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/alert`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetTicketNotificationPreferencesApi10ProfilePreferenceNotificationTicketGet
   * @summary Get Ticket Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/ticket
   * @secure
   */
  getTicketNotificationPreferencesApi10ProfilePreferenceNotificationTicketGet = (params: RequestParams = {}) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/ticket`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Iterates through the List[NotificationDigestPreferenceSet] specified on the PATCH request body. For each NotificationDigestPreference, updates the specified user preference to the desired severity. If the severity specified in NotificationDigestPreference.digests is None, the specified user preference is deleted.
   *
   * @tags profile
   * @name PatchTicketNotificationPreferenceApi10ProfilePreferenceNotificationTicketPatch
   * @summary Patch Ticket Notification Preference
   * @request PATCH:/api/1.0/profile/preference/notification/ticket
   * @secure
   */
  patchTicketNotificationPreferenceApi10ProfilePreferenceNotificationTicketPatch = (
    data: NotificationDigestPreferenceSet[],
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/ticket`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetArticleNotificationPreferencesApi10ProfilePreferenceNotificationArticleGet
   * @summary Get Article Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/article
   * @secure
   */
  getArticleNotificationPreferencesApi10ProfilePreferenceNotificationArticleGet = (params: RequestParams = {}) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/article`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Iterates through the List[NotificationDigestPreferenceSet] specified on the PATCH request body. For each NotificationDigestPreference, updates the specified user preference to the desired severity. If the severity specified in NotificationDigestPreference.digests is None, the specified user preference is deleted.
   *
   * @tags profile
   * @name PatchArticleNotificationPreferenceApi10ProfilePreferenceNotificationArticlePatch
   * @summary Patch Article Notification Preference
   * @request PATCH:/api/1.0/profile/preference/notification/article
   * @secure
   */
  patchArticleNotificationPreferenceApi10ProfilePreferenceNotificationArticlePatch = (
    data: NotificationDigestPreferenceSet[],
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/article`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetCreditCardNotificationPreferencesApi10ProfilePreferenceNotificationCreditCardReportGet
   * @summary Get Credit Card Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/credit-card-report
   * @secure
   */
  getCreditCardNotificationPreferencesApi10ProfilePreferenceNotificationCreditCardReportGet = (
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSimpleSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/credit-card-report`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PatchCreditCardNotificationPreferenceApi10ProfilePreferenceNotificationCreditCardReportPatch
   * @summary Patch Credit Card Notification Preference
   * @request PATCH:/api/1.0/profile/preference/notification/credit-card-report
   * @secure
   */
  patchCreditCardNotificationPreferenceApi10ProfilePreferenceNotificationCreditCardReportPatch = (
    data: SimpleNotificationDigestPreference,
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSimpleSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/credit-card-report`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name GetVictimNotificationPreferencesApi10ProfilePreferenceNotificationVictimReportGet
   * @summary Get Victim Notification Preferences
   * @request GET:/api/1.0/profile/preference/notification/victim-report
   * @secure
   */
  getVictimNotificationPreferencesApi10ProfilePreferenceNotificationVictimReportGet = (params: RequestParams = {}) =>
    this.request<NotificationDigestPreferenceSimpleSetListResponse, ErrorResponse>({
      path: `/api/1.0/profile/preference/notification/victim-report`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags profile
   * @name PatchVictimNotificationPreferenceApi10ProfilePreferenceNotificationVictimReportPatch
   * @summary Patch Victim Notification Preference
   * @request PATCH:/api/1.0/profile/preference/notification/victim-report
   * @secure
   */
  patchVictimNotificationPreferenceApi10ProfilePreferenceNotificationVictimReportPatch = (
    data: SimpleNotificationDigestPreference,
    params: RequestParams = {},
  ) =>
    this.request<NotificationDigestPreferenceSimpleSetListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/1.0/profile/preference/notification/victim-report`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reporting
   * @name GetReportsApi20ReportGet
   * @summary Get Reports
   * @request GET:/api/2.0/report/
   * @secure
   */
  getReportsApi20ReportGet = (query: GetReportsApi20ReportGetParams, params: RequestParams = {}) =>
    this.request<ReportPreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reporting
   * @name GenerateReportApi20ReportPost
   * @summary Generate Report
   * @request POST:/api/2.0/report/
   * @secure
   */
  generateReportApi20ReportPost = (data: ReportRequest, params: RequestParams = {}) =>
    this.request<DetailResponseReportPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Lists user-visible report types and filters out types requiring subscriptions that the current users business units don't have.
   *
   * @tags reporting
   * @name GetTypesApi20ReportTypeGet
   * @summary Get Types
   * @request GET:/api/2.0/report/type
   * @secure
   */
  getTypesApi20ReportTypeGet = (params: RequestParams = {}) =>
    this.request<ListResponseReportType, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/type`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description List Types and Templates for the business unit.
   *
   * @tags reporting
   * @name GetRequestOptionApi20ReportRequestOptionGet
   * @summary Get Request Option
   * @request GET:/api/2.0/report/request-option
   * @secure
   */
  getRequestOptionApi20ReportRequestOptionGet = (params: RequestParams = {}) =>
    this.request<ListResponseRequestOption, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/request-option`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Download latest report rendering
   *
   * @tags reporting
   * @name DownloadLatestReportApi20ReportReportExternalIdDownloadGet
   * @summary Download Latest Report
   * @request GET:/api/2.0/report/{report_external_id}/download
   * @secure
   */
  downloadLatestReportApi20ReportReportExternalIdDownloadGet = (reportExternalId: string, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/${reportExternalId}/download`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reporting
   * @name DeleteReportApi20ReportReportExternalIdDelete
   * @summary Delete Report
   * @request DELETE:/api/2.0/report/{report_external_id}
   * @secure
   */
  deleteReportApi20ReportReportExternalIdDelete = (reportExternalId: string, params: RequestParams = {}) =>
    this.request<DetailResponseReportPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/report/${reportExternalId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Endpoint for retrieving retainer information of user's business unit. If a retainer does not exist yet, return a mostly empty retainer preview just including the retainer type.
   *
   * @tags retainers
   * @name GetRetainerApi20RetainerGet
   * @summary Get Retainer
   * @request GET:/api/2.0/retainer/
   * @secure
   */
  getRetainerApi20RetainerGet = (params: RequestParams = {}) =>
    this.request<DetailResponseRetainerPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/retainer/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags retainers
   * @name CreateOrUpdateRetainerApi20RetainerPost
   * @summary Create Or Update Retainer
   * @request POST:/api/2.0/retainer/
   * @secure
   */
  createOrUpdateRetainerApi20RetainerPost = (data: RetainerCreateOrUpdateBody, params: RequestParams = {}) =>
    this.request<DetailResponseRetainerPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/retainer/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags retainers
   * @name DownloadRetainerAttachmentApi20RetainerAttachmentExternalIdGet
   * @summary Download Retainer Attachment
   * @request GET:/api/2.0/retainer/attachment/{external_id}/
   * @secure
   */
  downloadRetainerAttachmentApi20RetainerAttachmentExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/retainer/attachment/${externalId}/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags retainers
   * @name DeleteRetainerAttachmentApi20RetainerAttachmentExternalIdDelete
   * @summary Delete Retainer Attachment
   * @request DELETE:/api/2.0/retainer/attachment/{external_id}/
   * @secure
   */
  deleteRetainerAttachmentApi20RetainerAttachmentExternalIdDelete = (externalId: string, params: RequestParams = {}) =>
    this.request<void, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/retainer/attachment/${externalId}/`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Endpoint for uploading files to a user's retainer. If the retainer does not exist, it will be automatically created without contact information.
   *
   * @tags retainers
   * @name UploadRetainerAttachmentApi20RetainerAttachmentPost
   * @summary Upload Retainer Attachment
   * @request POST:/api/2.0/retainer/attachment/
   * @secure
   */
  uploadRetainerAttachmentApi20RetainerAttachmentPost = (
    data: BodyUploadRetainerAttachmentApi20RetainerAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<ListResponseRetainerAttachmentPreview, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/retainer/attachment/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardReportApi20CompromisedDataCreditCardReportExternalIdGet
   * @summary Get Credit Card Report
   * @request GET:/api/2.0/compromised-data/credit-card-report/{external_id}
   * @secure
   */
  getCreditCardReportApi20CompromisedDataCreditCardReportExternalIdGet = (
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseCreditCard, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name EditCreditCardReportApi20CompromisedDataCreditCardReportExternalIdPatch
   * @summary Edit Credit Card Report
   * @request PATCH:/api/2.0/compromised-data/credit-card-report/{external_id}
   * @secure
   */
  editCreditCardReportApi20CompromisedDataCreditCardReportExternalIdPatch = (
    externalId: string,
    data: ReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseCreditCard, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardReportsApi20CompromisedDataCreditCardReportGet
   * @summary Get Credit Card Reports
   * @request GET:/api/2.0/compromised-data/credit-card-report/
   * @secure
   */
  getCreditCardReportsApi20CompromisedDataCreditCardReportGet = (
    query: GetCreditCardReportsApi20CompromisedDataCreditCardReportGetParams,
    params: RequestParams = {},
  ) =>
    this.request<CreditCardPageResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardStatsStateApi20CompromisedDataCreditCardReportStatisticsStatusGet
   * @summary Get Credit Card Stats State
   * @request GET:/api/2.0/compromised-data/credit-card-report/statistics/status
   * @secure
   */
  getCreditCardStatsStateApi20CompromisedDataCreditCardReportStatisticsStatusGet = (
    query: GetCreditCardStatsStateApi20CompromisedDataCreditCardReportStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardHistogramApi20CompromisedDataCreditCardReportStatisticsMatcherHistogramGet
   * @summary Get Credit Card Histogram
   * @request GET:/api/2.0/compromised-data/credit-card-report/statistics/matcher/histogram
   * @secure
   */
  getCreditCardHistogramApi20CompromisedDataCreditCardReportStatisticsMatcherHistogramGet = (
    query: GetCreditCardHistogramApi20CompromisedDataCreditCardReportStatisticsMatcherHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/statistics/matcher/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGet
   * @summary Get Credit Card Source Stats
   * @request GET:/api/2.0/compromised-data/credit-card-report/statistics/source
   * @secure
   */
  getCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGet = (
    query: GetCreditCardSourceStatsApi20CompromisedDataCreditCardReportStatisticsSourceGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/statistics/source`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGet
   * @summary Get Credit Card Export Csv
   * @request GET:/api/2.0/compromised-data/credit-card-report/export/csv
   * @secure
   */
  getCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGet = (
    query: GetCreditCardExportCsvApi20CompromisedDataCreditCardReportExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name BulkEditCreditCardReportsApi20CompromisedDataCreditCardReportBulkPatch
   * @summary Bulk Edit Credit Card Reports
   * @request PATCH:/api/2.0/compromised-data/credit-card-report/bulk
   * @secure
   */
  bulkEditCreditCardReportsApi20CompromisedDataCreditCardReportBulkPatch = (
    data: BulkReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseBulkReportUpdateResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credit-card-report/bulk`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimReportApi20CompromisedDataCredentialReportExternalIdGet
   * @summary Get Victim Report
   * @request GET:/api/2.0/compromised-data/credential-report/{external_id}
   * @secure
   */
  getVictimReportApi20CompromisedDataCredentialReportExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<DetailResponseVictim, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name EditVictimReportApi20CompromisedDataCredentialReportExternalIdPatch
   * @summary Edit Victim Report
   * @request PATCH:/api/2.0/compromised-data/credential-report/{external_id}
   * @secure
   */
  editVictimReportApi20CompromisedDataCredentialReportExternalIdPatch = (
    externalId: string,
    data: ReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseVictim, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimReportsApi20CompromisedDataCredentialReportGet
   * @summary Get Victim Reports
   * @request GET:/api/2.0/compromised-data/credential-report/
   * @secure
   */
  getVictimReportsApi20CompromisedDataCredentialReportGet = (
    query: GetVictimReportsApi20CompromisedDataCredentialReportGetParams,
    params: RequestParams = {},
  ) =>
    this.request<VictimPageResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGet
   * @summary Get Victim Stats State
   * @request GET:/api/2.0/compromised-data/credential-report/statistics/status
   * @secure
   */
  getVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGet = (
    query: GetVictimStatsStateApi20CompromisedDataCredentialReportStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGet
   * @summary Get Victim Histogram
   * @request GET:/api/2.0/compromised-data/credential-report/statistics/matcher/histogram
   * @secure
   */
  getVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGet = (
    query: GetVictimHistogramApi20CompromisedDataCredentialReportStatisticsMatcherHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/statistics/matcher/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimSourceStatsApi20CompromisedDataCredentialReportStatisticsSourceGet
   * @summary Get Victim Source Stats
   * @request GET:/api/2.0/compromised-data/credential-report/statistics/source
   * @secure
   */
  getVictimSourceStatsApi20CompromisedDataCredentialReportStatisticsSourceGet = (
    query: GetVictimSourceStatsApi20CompromisedDataCredentialReportStatisticsSourceGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/statistics/source`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name GetVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGet
   * @summary Get Victim Export Csv
   * @request GET:/api/2.0/compromised-data/credential-report/export/csv
   * @secure
   */
  getVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGet = (
    query: GetVictimExportCsvApi20CompromisedDataCredentialReportExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags compromised-data
   * @name BulkEditVictimReportsApi20CompromisedDataCredentialReportBulkPatch
   * @summary Bulk Edit Victim Reports
   * @request PATCH:/api/2.0/compromised-data/credential-report/bulk
   * @secure
   */
  bulkEditVictimReportsApi20CompromisedDataCredentialReportBulkPatch = (
    data: BulkReportUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseBulkReportUpdateResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/compromised-data/credential-report/bulk`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetFlagsApi20TicketFlagGet
   * @summary Get Flags
   * @request GET:/api/2.0/ticket/flag
   * @secure
   */
  getFlagsApi20TicketFlagGet = (params: RequestParams = {}) =>
    this.request<TicketFlagTypePreviewListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/flag`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetTicketApi20TicketExternalIdGet
   * @summary Get Ticket
   * @request GET:/api/2.0/ticket/{external_id}
   * @secure
   */
  getTicketApi20TicketExternalIdGet = (externalId: string, params: RequestParams = {}) =>
    this.request<TicketFullDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name UpdateTicketApi20TicketExternalIdPatch
   * @summary Update Ticket
   * @request PATCH:/api/2.0/ticket/{external_id}
   * @secure
   */
  updateTicketApi20TicketExternalIdPatch = (externalId: string, data: TicketUpdateBody, params: RequestParams = {}) =>
    this.request<TicketFullDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetCommentsApi20TicketExternalIdCommentGet
   * @summary Get Comments
   * @request GET:/api/2.0/ticket/{external_id}/comment
   * @secure
   */
  getCommentsApi20TicketExternalIdCommentGet = (externalId: string, params: RequestParams = {}) =>
    this.request<TicketCommentPreviewListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}/comment`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name CreateCommentApi20TicketExternalIdCommentPost
   * @summary Create Comment
   * @request POST:/api/2.0/ticket/{external_id}/comment
   * @secure
   */
  createCommentApi20TicketExternalIdCommentPost = (
    externalId: string,
    data: TicketCommentCreateBody,
    params: RequestParams = {},
  ) =>
    this.request<TicketCommentPreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}/comment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetAttachmentApi20TicketExternalIdAttachmentAttachmentExternalIdGet
   * @summary Get Attachment
   * @request GET:/api/2.0/ticket/{external_id}/attachment/{attachment_external_id}
   * @secure
   */
  getAttachmentApi20TicketExternalIdAttachmentAttachmentExternalIdGet = (
    attachmentExternalId: any,
    externalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetTicketsApi20TicketGet
   * @summary Get Tickets
   * @request GET:/api/2.0/ticket/
   * @secure
   */
  getTicketsApi20TicketGet = (query: GetTicketsApi20TicketGetParams, params: RequestParams = {}) =>
    this.request<TicketPreviewPageDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name CreateTicketApi20TicketPost
   * @summary Create Ticket
   * @request POST:/api/2.0/ticket/
   * @secure
   */
  createTicketApi20TicketPost = (data: TicketCreateBody, params: RequestParams = {}) =>
    this.request<TicketFullDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetExportCsvApi20TicketExportCsvGet
   * @summary Get Export Csv
   * @request GET:/api/2.0/ticket/export/csv
   * @secure
   */
  getExportCsvApi20TicketExportCsvGet = (
    query: GetExportCsvApi20TicketExportCsvGetParams,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/export/csv`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsStatusApi20TicketStatisticsStatusGet
   * @summary Get Statistics Status
   * @request GET:/api/2.0/ticket/statistics/status
   * @secure
   */
  getStatisticsStatusApi20TicketStatisticsStatusGet = (
    query: GetStatisticsStatusApi20TicketStatisticsStatusGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/statistics/status`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsSeverityApi20TicketStatisticsSeverityGet
   * @summary Get Statistics Severity
   * @request GET:/api/2.0/ticket/statistics/severity
   * @secure
   */
  getStatisticsSeverityApi20TicketStatisticsSeverityGet = (
    query: GetStatisticsSeverityApi20TicketStatisticsSeverityGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/statistics/severity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsSeverityHistogramApi20TicketStatisticsSeverityHistogramGet
   * @summary Get Statistics Severity Histogram
   * @request GET:/api/2.0/ticket/statistics/severity/histogram
   * @secure
   */
  getStatisticsSeverityHistogramApi20TicketStatisticsSeverityHistogramGet = (
    query: GetStatisticsSeverityHistogramApi20TicketStatisticsSeverityHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/statistics/severity/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsTypeApi20TicketStatisticsTypeGet
   * @summary Get Statistics Type
   * @request GET:/api/2.0/ticket/statistics/type
   * @secure
   */
  getStatisticsTypeApi20TicketStatisticsTypeGet = (
    query: GetStatisticsTypeApi20TicketStatisticsTypeGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/statistics/type`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name GetStatisticsTypeHistogramApi20TicketStatisticsTypeHistogramGet
   * @summary Get Statistics Type Histogram
   * @request GET:/api/2.0/ticket/statistics/type/histogram
   * @secure
   */
  getStatisticsTypeHistogramApi20TicketStatisticsTypeHistogramGet = (
    query: GetStatisticsTypeHistogramApi20TicketStatisticsTypeHistogramGetParams,
    params: RequestParams = {},
  ) =>
    this.request<StatisticsHistogramCountListResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/statistics/type/histogram`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name CreateFeedbackApi20TicketExternalIdFeedbackPost
   * @summary Create Feedback
   * @request POST:/api/2.0/ticket/{external_id}/feedback
   * @secure
   */
  createFeedbackApi20TicketExternalIdFeedbackPost = (
    externalId: string,
    data: TicketFeedbackCreateBody,
    params: RequestParams = {},
  ) =>
    this.request<TicketFeedbackPreviewDetailResponse, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/${externalId}/feedback`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags tickets
   * @name UploadAttachmentApi20TicketAttachmentPost
   * @summary Upload Attachment
   * @request POST:/api/2.0/ticket/attachment
   * @secure
   */
  uploadAttachmentApi20TicketAttachmentPost = (
    data: BodyUploadAttachmentApi20TicketAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<any, ErrorResponse | HTTPValidationError>({
      path: `/api/2.0/ticket/attachment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * @description This endpoint also sets a session cookie containing the CSRF token.
   *
   * @name CsrfTokenRouteApi10CsrfTokenGet
   * @summary Csrf Token Route
   * @request GET:/api/1.0/csrf-token
   */
  csrfTokenRouteApi10CsrfTokenGet = (params: RequestParams = {}) =>
    this.request<DetailResponseCsrfToken, any>({
      path: `/api/1.0/csrf-token`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reset
   * @name IndexApi10ResetGet
   * @summary Index
   * @request GET:/api/1.0/reset/
   */
  indexApi10ResetGet = (params: RequestParams = {}) =>
    this.request<BaseResponse, HTTPValidationError>({
      path: `/api/1.0/reset/`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reset
   * @name CreatePasswordResetApi10ResetPasswordResetEmailPost
   * @summary Create Password Reset
   * @request POST:/api/1.0/reset/password-reset-email
   */
  createPasswordResetApi10ResetPasswordResetEmailPost = (data: EmailPasswordResetInput, params: RequestParams = {}) =>
    this.request<BaseResponse, HTTPValidationError>({
      path: `/api/1.0/reset/password-reset-email`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reset
   * @name SetPasswordApi10ResetPasswordPost
   * @summary Set Password
   * @request POST:/api/1.0/reset/password
   */
  setPasswordApi10ResetPasswordPost = (data: SetPasswordRequestBody, params: RequestParams = {}) =>
    this.request<BaseResponse, HTTPValidationError>({
      path: `/api/1.0/reset/password`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags reset
   * @name CheckTokenApi10ResetCheckTokenPost
   * @summary Check Token
   * @request POST:/api/1.0/reset/check-token
   */
  checkTokenApi10ResetCheckTokenPost = (data: CheckResetTokenBody, params: RequestParams = {}) =>
    this.request<TokenStatusResponse, HTTPValidationError>({
      path: `/api/1.0/reset/check-token`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve details of an ER Case using a submission code. The ER Case is automatically submitted the first time a user requests its details.
   *
   * @tags emergency-response-cases
   * @name GetEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGet
   * @summary Get Emergency Response Case Info
   * @request GET:/api/1.0/emergency-response-case/{er_case_external_id}
   */
  getEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGet = (
    { erCaseExternalId, ...query }: GetEmergencyResponseCaseInfoApi10EmergencyResponseCaseErCaseExternalIdGetParams,
    params: RequestParams = {},
  ) =>
    this.request<ERCaseSubmissionPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name RetrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGet
   * @summary Retrieve All Er Case Attachments With Submission Code
   * @request GET:/api/1.0/emergency-response-case/{er_case_external_id}/attachment
   */
  retrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGet = (
    {
      erCaseExternalId,
      ...query
    }: RetrieveAllErCaseAttachmentsWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentGetParams,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCaseAttachmentsPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}/attachment`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name UploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost
   * @summary Upload Er Case Attachment With Submission Code
   * @request POST:/api/1.0/emergency-response-case/{er_case_external_id}/attachment
   */
  uploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost = (
    {
      erCaseExternalId,
      ...query
    }: UploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPostParams,
    data: BodyUploadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCaseAttachmentsPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}/attachment`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name DownloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGet
   * @summary Download Er Case Attachment With Submission Code
   * @request GET:/api/1.0/emergency-response-case/{er_case_external_id}/attachment/{er_case_attachment_external_id}
   */
  downloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGet =
    (
      {
        erCaseExternalId,
        erCaseAttachmentExternalId,
        ...query
      }: DownloadErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdGetParams,
      params: RequestParams = {},
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/api/1.0/emergency-response-case/${erCaseExternalId}/attachment/${erCaseAttachmentExternalId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name DeleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDelete
   * @summary Delete Er Case Attachment With Submission Code
   * @request DELETE:/api/1.0/emergency-response-case/{er_case_external_id}/attachment/{er_case_attachment_external_id}
   */
  deleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDelete =
    (
      {
        erCaseExternalId,
        erCaseAttachmentExternalId,
        ...query
      }: DeleteErCaseAttachmentWithSubmissionCodeApi10EmergencyResponseCaseErCaseExternalIdAttachmentErCaseAttachmentExternalIdDeleteParams,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/api/1.0/emergency-response-case/${erCaseExternalId}/attachment/${erCaseAttachmentExternalId}`,
        method: "DELETE",
        query: query,
        ...params,
      });
  /**
   * No description
   *
   * @tags emergency-response-cases
   * @name SetErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatch
   * @summary Set Er Case Questionnaire Data
   * @request PATCH:/api/1.0/emergency-response-case/{er_case_external_id}/questionnaire
   */
  setErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatch = (
    {
      erCaseExternalId,
      ...query
    }: SetErCaseQuestionnaireDataApi10EmergencyResponseCaseErCaseExternalIdQuestionnairePatchParams,
    data: ERCaseQuestionnaireUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<ERCaseQuestionnairePreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}/questionnaire`,
      method: "PATCH",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Callback URL for twilio to acknowledge an ER Case.
   *
   * @tags emergency-response-cases
   * @name AcknowledgeEmergencyResponseCaseApi10EmergencyResponseCaseErCaseExternalIdCallbackTigerTeamPost
   * @summary Acknowledge Emergency Response Case
   * @request POST:/api/1.0/emergency-response-case/{er_case_external_id}/callback/tiger-team
   * @secure
   */
  acknowledgeEmergencyResponseCaseApi10EmergencyResponseCaseErCaseExternalIdCallbackTigerTeamPost = (
    erCaseExternalId: string,
    data: BodyAcknowledgeEmergencyResponseCaseApi10EmergencyResponseCaseErCaseExternalIdCallbackTigerTeamPost,
    params: RequestParams = {},
  ) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/emergency-response-case/${erCaseExternalId}/callback/tiger-team`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.UrlEncoded,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve a list of business units accessible to the authenticated admin user. Sort business units by their name and filter by feature flags if needed.
   *
   * @tags admin-business-unit
   * @name GetBusinessUnitsApi10AdminBusinessUnitGet
   * @summary Get Business Units
   * @request GET:/api/1.0/admin/business-unit/
   * @secure
   */
  getBusinessUnitsApi10AdminBusinessUnitGet = (
    query: GetBusinessUnitsApi10AdminBusinessUnitGetParams,
    params: RequestParams = {},
  ) =>
    this.request<BusinessUnitPreviewPageDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/business-unit/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve a list of business units accessible to the authenticated admin user, along with information about their associated retainers and retainer attachments. Sort business units by their name and filter by name if needed.
   *
   * @tags admin-retainers
   * @name GetBusinessUnitsWithRetainersByAdminApi10AdminRetainerGet
   * @summary Get Business Units With Retainers By Admin
   * @request GET:/api/1.0/admin/retainer/
   * @secure
   */
  getBusinessUnitsWithRetainersByAdminApi10AdminRetainerGet = (
    query: GetBusinessUnitsWithRetainersByAdminApi10AdminRetainerGetParams,
    params: RequestParams = {},
  ) =>
    this.request<BusinessUnitWithRetainerPreviewPageDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name CreateRetainerByAdminApi10AdminRetainerPost
   * @summary Create Retainer By Admin
   * @request POST:/api/1.0/admin/retainer/
   * @secure
   */
  createRetainerByAdminApi10AdminRetainerPost = (data: RetainerCreateBody, params: RequestParams = {}) =>
    this.request<DetailResponseRetainerPreview, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name GetRetainerTypesApi10AdminRetainerTypeGet
   * @summary Get Retainer Types
   * @request GET:/api/1.0/admin/retainer/type
   * @secure
   */
  getRetainerTypesApi10AdminRetainerTypeGet = (params: RequestParams = {}) =>
    this.request<RetainerTypePreviewListResponse, any>({
      path: `/api/1.0/admin/retainer/type`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get an ER Retainer its external id if it is accessible to the user, along with information about its business unit and attachments.
   *
   * @tags admin-retainers
   * @name GetRetainerByExternalIdByAdminApi10AdminRetainerRetainerExternalIdGet
   * @summary Get Retainer By External Id By Admin
   * @request GET:/api/1.0/admin/retainer/{retainer_external_id}
   * @secure
   */
  getRetainerByExternalIdByAdminApi10AdminRetainerRetainerExternalIdGet = (
    retainerExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<RetainerWithBusinessUnitPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name UpdateRetainerByAdminApi10AdminRetainerRetainerExternalIdPut
   * @summary Update Retainer By Admin
   * @request PUT:/api/1.0/admin/retainer/{retainer_external_id}
   * @secure
   */
  updateRetainerByAdminApi10AdminRetainerRetainerExternalIdPut = (
    retainerExternalId: string,
    data: RetainerCreateOrUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<DetailResponseRetainerPreview, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name DownloadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdGet
   * @summary Download Retainer Attachment By Admin
   * @request GET:/api/1.0/admin/retainer/{retainer_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  downloadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdGet = (
    retainerExternalId: string,
    attachmentExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name DeleteRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdDelete
   * @summary Delete Retainer Attachment By Admin
   * @request DELETE:/api/1.0/admin/retainer/{retainer_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  deleteRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentAttachmentExternalIdDelete = (
    retainerExternalId: string,
    attachmentExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<void, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}/attachment/${attachmentExternalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-retainers
   * @name UploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost
   * @summary Upload Retainer Attachment By Admin
   * @request POST:/api/1.0/admin/retainer/{retainer_external_id}/attachment/
   * @secure
   */
  uploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost = (
    retainerExternalId: string,
    data: BodyUploadRetainerAttachmentByAdminApi10AdminRetainerRetainerExternalIdAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<ListResponseRetainerAttachmentPreview, HTTPValidationError>({
      path: `/api/1.0/admin/retainer/${retainerExternalId}/attachment/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name GetEmergencyResponseCasesApi10AdminEmergencyResponseCaseGet
   * @summary Get Emergency Response Cases
   * @request GET:/api/1.0/admin/emergency-response-case/
   * @secure
   */
  getEmergencyResponseCasesApi10AdminEmergencyResponseCaseGet = (
    query: GetEmergencyResponseCasesApi10AdminEmergencyResponseCaseGetParams,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCasePreviewPageDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name CreateNewEmergencyResponseCaseApi10AdminEmergencyResponseCasePost
   * @summary Create New Emergency Response Case
   * @request POST:/api/1.0/admin/emergency-response-case/
   * @secure
   */
  createNewEmergencyResponseCaseApi10AdminEmergencyResponseCasePost = (
    data: EmergencyResponseCaseCreateBody,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCasePreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name GetEmergencyResponseCasesByExternalIdApi10AdminEmergencyResponseCaseErCaseExternalIdGet
   * @summary Get Emergency Response Cases By External Id
   * @request GET:/api/1.0/admin/emergency-response-case/{er_case_external_id}
   * @secure
   */
  getEmergencyResponseCasesByExternalIdApi10AdminEmergencyResponseCaseErCaseExternalIdGet = (
    erCaseExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCasePreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name UpdateExistingEmergencyResponseCaseApi10AdminEmergencyResponseCaseErCaseExternalIdPatch
   * @summary Update Existing Emergency Response Case
   * @request PATCH:/api/1.0/admin/emergency-response-case/{er_case_external_id}
   * @secure
   */
  updateExistingEmergencyResponseCaseApi10AdminEmergencyResponseCaseErCaseExternalIdPatch = (
    erCaseExternalId: string,
    data: EmergencyResponseCaseUpdateBody,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCasePreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name ResendNewCaseMessageApi10AdminEmergencyResponseCaseErCaseExternalIdMessageMessageTypePost
   * @summary Resend New Case Message
   * @request POST:/api/1.0/admin/emergency-response-case/{er_case_external_id}/message/{message_type}
   * @secure
   */
  resendNewCaseMessageApi10AdminEmergencyResponseCaseErCaseExternalIdMessageMessageTypePost = (
    erCaseExternalId: string,
    messageType: ResendMessageType,
    params: RequestParams = {},
  ) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}/message/${messageType}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name UploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost
   * @summary Upload Er Case Attachment
   * @request POST:/api/1.0/admin/emergency-response-case/{er_case_external_id}/attachment
   * @secure
   */
  uploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost = (
    erCaseExternalId: string,
    data: BodyUploadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentPost,
    params: RequestParams = {},
  ) =>
    this.request<EmergencyResponseCaseAttachmentsPreviewDetailResponse, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}/attachment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name DownloadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdGet
   * @summary Download Er Case Attachment
   * @request GET:/api/1.0/admin/emergency-response-case/{er_case_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  downloadErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdGet = (
    erCaseExternalId: string,
    attachmentExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<any, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}/attachment/${attachmentExternalId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-emergency-response-cases
   * @name DeleteErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdDelete
   * @summary Delete Er Case Attachment
   * @request DELETE:/api/1.0/admin/emergency-response-case/{er_case_external_id}/attachment/{attachment_external_id}
   * @secure
   */
  deleteErCaseAttachmentApi10AdminEmergencyResponseCaseErCaseExternalIdAttachmentAttachmentExternalIdDelete = (
    erCaseExternalId: string,
    attachmentExternalId: string,
    params: RequestParams = {},
  ) =>
    this.request<void, HTTPValidationError>({
      path: `/api/1.0/admin/emergency-response-case/${erCaseExternalId}/attachment/${attachmentExternalId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Retrieve a list of active admin users accessible to the authenticated admin user.
   *
   * @tags admin-user
   * @name GetAdminUsersApi10AdminUserAdminGet
   * @summary Get Admin Users
   * @request GET:/api/1.0/admin/user/admin
   * @secure
   */
  getAdminUsersApi10AdminUserAdminGet = (params: RequestParams = {}) =>
    this.request<AdminUserPreviewListResponse, any>({
      path: `/api/1.0/admin/user/admin`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
