import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import {
  ServiceSubscriptionRead,
  ServiceSubscriptionReadListResponse,
} from "@csis.com/tip/src/api/openapi/data-contracts";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { handleRequestError } from "../../../../../api/utils";

const serviceSubscriptionsSlice = createAsyncArrayFetchSlice<
  ServiceSubscriptionRead,
  string
>("serviceSubscriptions");

export default serviceSubscriptionsSlice.reducer;

export const {
  fetchData: fetchServiceSubscriptions,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = serviceSubscriptionsSlice.actions;

// Async stuff - sagas

function* fetchServiceSubscriptionsSaga(action: PayloadAction<string>) {
  const query = {
    organization_id: action.payload,
  };

  yield put(setIsPending());
  try {
    const response: AxiosResponse<ServiceSubscriptionReadListResponse> =
      yield call(
        axiosCsisApi.getBaseServiceSubscriptionsApi10InternalServiceSubscriptionBaseGet,
        query,
      );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setFetchError(errorMessage));
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchServiceSubscriptions.toString(),
    fetchServiceSubscriptionsSaga,
  );
}

export function* serviceSubscriptionsSagas() {
  yield all([actionWatcher()]);
}
